import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiUrl = environment.baseURL;
  constructor(private translateService: TranslateService) {}
  removeLoginBanner() {
    const loginBanner = document.getElementsByTagName('login-banner');
    loginBanner[0].parentNode?.removeChild(loginBanner[0]);
  }

  getDateFormatByLang(shortDate?: boolean) {
    const lang = this.translateService.currentLang || 'en';
    const dateFormats: any = {
      'en': 'MM/dd/yyyy',
      'vi': 'dd/MM/yyyy'
    };
    const dateFormatsShort: any = {
      'en': 'mm/dd/yy',
      'vi': 'dd/mm/yy'
    };

    if (shortDate) return dateFormatsShort[lang.toLowerCase()];
    return dateFormats[lang.toLowerCase()];
  }

  checkStringDate(dateString: string) {
    // Regular expressions to match DD/MM/yyyy and MM/dd/yyyy formats
    const ddmmyyyyRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const mmddyyyyRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    return ddmmyyyyRegex.test(dateString) || mmddyyyyRegex.test(dateString);
  }

  convertStringToDate(dateString: string) {
    const format = this.getDateFormatByLang();
    let day, month, year;
    const parts = dateString.split('/');
    if (parts !== null) {
      switch (format) {
        case 'dd/MM/yyyy':
          day = +parts[0];
          month = +parts[1];
          year = +parts[2];
          break;
        case 'MM/dd/yyyy':
        default:
          day = +parts[1];
          month = +parts[0];
          year = +parts[2];
          break;
      }
      return new Date(year, month - 1, day);
    }
    return null;
  }

  getImageUrl(id: string | undefined): string {
    if (!id) {
      return '';
    }
    const isPath = id.startsWith('/');
    const webPath = isPath ? '' : '/storage/files/web/';
    const addWebp = isPath ? '' : '.webp';

    return `${this.apiUrl}${webPath}${id}${addWebp}`;
  }

  removeVietnameseTones(str: string) {
    // Normalize the string to separate the base character from the diacritical marks
    str = str.normalize('NFD');

    // Remove the diacritical marks
    str = str.replace(/[\u0300-\u036f]/g, '');

    // Replace special Vietnamese characters
    str = str.replace(/[đĐ]/g, 'd');

    return str;
  }

  convertToSnakeCaseString(str: string) {
    str = this.removeVietnameseTones(str).trim();

    const format = str
      // Replace spaces with hyphens
      .replace(/\s+/g, '-')
      // Convert to lowercase
      .toLowerCase();
    str = encodeURIComponent(format)
      // replace '(' to '%28' and ')' to '%29' because of encodeURIComponent not handle for that 2 strings
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29');
    return str;
  }
}
