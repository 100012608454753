import { UserAPI } from '@app/lib/api/user/api.user.model';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BlockListState } from './users.state';

export const UserInfoActions = createActionGroup({
  source: 'UserInfo',
  events: {
    'On Get User Info': props<{ userId: string }>(),
    'On Update User Name': props<{ first_name: string; last_name: string }>(),
    'On Update Avatar User Info': props<{ avatar_thumbnail_url: string }>(),
    'On Update Frame User Info': props<{ frame_url: string }>(),
    'On Get Blocking User Bloom Filter': props<{ bloomString: string }>(),
    'On Get Blocked User Bloom Filter': props<{ bloomString: string }>(),
    // bloom filter comment
    'On Get Comment Bloom Filter': emptyProps(),
    // bloom filter coin
    'On Get Coin Bloom Filter': emptyProps(),
    'On Get Current Region': props<{ countryCode: string }>(),
    'On Update Property User Info': props<{ property: any }>()
  }
});

export const UserInfoApiActions = createActionGroup({
  source: 'UserInfo Api',
  events: {
    'On Get User Info Success': props<{ userInfo: UserAPI.UserInfo }>(),
    'On Get User Info Fail': props<{ message: string }>(),
    // bloom filter comment
    'On Get Comment Bloom Filter Success': props<{ bloomString: string }>(),
    // bloom filter comment
    'On Get Coin Bloom Filter Success': props<{ bloomString: string }>()
  }
});

export const BlockUserActions = createActionGroup({
  source: 'BlockUser',
  events: {
    'On Block User': props<{ userId: string; fullName: string }>(),
    'On Get Blocking List': props<{ pageNum: number; pageSize: number }>(),
    'On UnBlock User': props<{ blockId: string }>(),
    'On Delete Item Block List': props<{ blockId: string }>(),
    'On Destroy Block User': emptyProps(),
    'On Update Item Block List': props<{ blockId: string; key: string; value: any }>()
  }
});

export const BlockUserApiActions = createActionGroup({
  source: 'BlockUser Api',
  events: {
    'On Block User Success': props<{ blockData: UserAPI.BlockUser; fullName: string }>(),
    'On Block User Fail': props<{ error: string }>(),

    'On Get Blocking List Success': props<{ blockingList: BlockListState }>(),
    'On Get Blocking List Fail': props<{ error: string }>(),

    'On UnBlock User Success': props<{ blockData: UserAPI.BlockUser }>(),
    'On UnBlock User Fail': props<{ message: string }>()
  }
});
