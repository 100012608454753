export const ADS_CATEGORY = {
  adsSearch: 'AS',
  adsFeed: 'AF',
  adsStory: 'AST',
  adsPanel1: 'AP1',
  adsPanel2: 'AP2',
  adsSponsoredBy: 'ASP',
  url: 'U',
  post: 'PO',
  image: 'I',
  video: 'V',
  fanpage: 'FP',
  group: 'G',
  story: 'ST',
  deviceUsing: 'DU'
};

export const ADS_ACTION = {
  view: 'V',
  viewBrief: 'VB',
  viewQuick: 'VQ',
  viewShort: 'VSH',
  viewLong: 'VL',
  reaction: 'RE',
  share: 'SH',
  comment: 'CM',
  read: 'R',
  click: 'C',
  play: 'PL',
  pause: 'PA',
  skipToNext: 'VN',
  skipToPrevious: 'VP',
  fullScreen: 'VF',
  zoom: 'Z',
  explore: 'E',
  submit: 'S',
  profile: 'FP',
  follow: 'FL',
  visit: 'VI',
  checkIn: 'CH',
  distribute: 'D',
  deviceTracking: 'DT'
};

export const FOCUS_DEBOUNCE_TIMES = {
  immediate: { totalTime: 100, time: 100, label: 'W' },
  short: { totalTime: 3000, time: 2900, label: 'W3S' },
  long: { totalTime: 10000, time: 7000, label: 'W10S' },
  veryLong: { totalTime: 30000, time: 20000, label: 'W30S' }
}; //TODO: The label is used temporarily to track interaction times until supported by BE

export const PLAY_VIDEO_EVENT_TIMES = {
  brief: { totalTime: 100, time: 100, action: ADS_ACTION.viewBrief, playPercent: NaN }, //TODO: Temporary reduce this time until support new event
  quick: { totalTime: 10000, time: 9900, action: ADS_ACTION.viewQuick, playPercent: NaN }, //time = totalTime - totalTime of the  above event
  short: { totalTime: 30000, time: 20000, action: ADS_ACTION.viewShort, playPercent: NaN },
  long: { totalTime: NaN, time: NaN, action: ADS_ACTION.viewLong, playPercent: 0.9 } //Play Video Percentage = playPercent * 100%
};

export const ELEMENT_TRACKING_RATIO = {
  visibleHeight: 0.5, //(Visible height / Element height) >= 50%
  visibleInViewport: 0.7 //(Visible height / Viewport height) >= 70%
};

export const VIDEO_INTERACTION_TYPES = {
  viewFullScreen: ADS_ACTION.fullScreen,
  viewBrief: ADS_ACTION.viewBrief,
  viewQuick: ADS_ACTION.viewQuick,
  viewShort: ADS_ACTION.viewShort,
  viewLong: ADS_ACTION.viewLong,
  play: ADS_ACTION.play,
  pause: ADS_ACTION.pause
};
