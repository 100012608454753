<figure #videoContainer class="w-full h-full video-container relative group">
  <video
    #video
    (click)="onClickVideo()"
    [appHlsVideo]="validUrl(item.original, item.upload)"
    class="w-full h-full object-center bg-palette-gray-900 custom-video cursor-pointer"
    [ngClass]="{ 'object-cover': isCover, 'aspect-auto': isUsingAspect }"
    [muted]="isMuted"
    (play)="playVideoEvent(true, item.original.replace('.webm', ''), video.duration)"
    (pause)="playVideoEvent(false, item.original.replace('.webm', ''), video.duration)"
    [hidden]="isWaiting"
    [autoplay]="isIOSDevice"
    playsinline>
  </video>
  <div
    *ngIf="totalViews"
    [hidden]="isWaiting || viewFullscreen"
    class="absolute bottom-2 right-2 md:bottom-4 md:right-4 z-10 group-hover:-translate-y-6 transition-transform">
    <div class="bg-palette-gray-900/30 text-palette-gray-100 text-xs/3 font-medium px-2 py-1 rounded-full">
      {{
        (totalViews === 1 ? 'MEDIA.ONE_VIEW' : 'MEDIA.TOTAL_VIEWS') | translate: { quantity: totalViews | shortNumber }
      }}
    </div>
  </div>
  <div
    class="w-full h-[5%] absolute bottom-0 right-0 py-6 px-3 flex justify-end items-center controls-container gap-3"
    [hidden]="isWaiting">
    <div class="flex gap-2" *ngIf="duration === 0 || currentTime !== duration">
      <i
        *ngIf="currentWidth > videoWidthToShowController"
        class="sctr-icon-solid-fast-backward text-base text-palette-base-white cursor-pointer"
        (click)="updateTime(true)"></i>
      <i
        class="text-base text-palette-base-white cursor-pointer"
        [ngClass]="!video.paused ? 'sctr-icon-solid-pause-circle' : 'sctr-icon-solid-play-circle'"
        (click)="playPause()"></i>
      <i
        *ngIf="currentWidth > videoWidthToShowController"
        class="sctr-icon-solid-fast-forward text-base text-palette-base-white cursor-pointer"
        (click)="updateTime(false)"></i>
    </div>
    <i *ngIf="duration !== 0 && currentTime === duration"
      class="sctr-icon-refresh-ccw-01 text-base text-palette-base-white cursor-pointer"
      (click)="playPause()"></i>
    <div
      (click)="isClickedPlay.emit(true)"
      class="w-full flex gap-2 items-center"
      (mouseover)="visiblePlayingPoint = true"
      (mouseout)="visiblePlayingPoint = false">
      <div *ngIf="isShowProgress" class="text-palette-base-white w-12">{{ currentTime }}</div>
      <div #progressBar class="w-full progress-bar" (mousemove)="onSliderHover($event)">
        <div class="my-3 bg-palette-gray-500 h-[3px] rounded-full w-full cursor-pointer relative">
          <div
            #loadedData
            class="flex justify-end items-center bg-palette-gray-200 h-[3px] absolute transitionProgress"></div>
          <div #progressFilled class="flex justify-end items-center bg-branding-primary-600 h-[3px] absolute">
            <div
              class="w-1.5 h-1.5 bg-branding-primary-600 border border-palette-base-white absolute rounded-full"
              [ngStyle]="{ 'visibility': visiblePlayingPoint ? 'visible' : 'hidden' }"></div>
          </div>
          <div #snapshotsRef class="w-32 h-20 absolute bottom-[35px] border-palette-base-white border-2 snapshotContainer">
            <div [id]="'box-view'" class="w-full h-full object-contain bg-palette-gray-900"></div>
            <div class="relative flex place-content-center">
              <p class="text-palette-base-white bg-hoveredTime font-semibold text-xs absolute bottom-1.5 px-2 rounded-lg item-center">{{caculateTime(snapshotsTime)}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isShowProgress" class="text-palette-base-white w-12">{{ duration }}</div>
    </div>
    
    <div 
    (mouseleave)="showVolumeSlider = false"
    class="flex gap-4">
    <!-- reopen when implement these action -->
      <!-- <i
        class="sctr-icon-solid-settings-01 text-base text-palette-base-white cursor-pointer"
        (click)="isClickedPlay.emit(true)"></i> -->
      <i *ngIf="isShowScaleIcon"
        class="sctr-icon-solid-scale-01 text-base text-palette-base-white cursor-pointer"
        (click)="handleScaleVideo()"></i>
      <div class="relative">
        <i class="text-base text-palette-base-white cursor-pointer"
          [ngClass]="{ 'sctr-icon-volume-max': !video.muted, 'sctr-icon-volume-x': video.muted }"
          (click)="onMuted()"
          (mouseenter)="showVolumeSlider = true"
        ></i>
        <input 
        class="absolute top-[-15px] left-[15px] w-[50px] transform -rotate-90 transform-origin-left-bottom"
        *ngIf="showVolumeSlider" type="range" [(ngModel)]="volume" (input)="updateVolume()" min="0" max="1" step="0.1">
      </div>
      <i
        class="sctr-icon-maximize-02 text-base text-palette-base-white cursor-pointer"
        (click)="onFullscreen(item.original.replace('.webm', ''))"></i>
    </div>
  </div>
</figure>
