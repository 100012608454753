import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { RelationshipStatusEnum } from '@app/core/enums/makeFriend.enum';
import { FollowerService } from '@app/core/services/follower.service';
import { FriendService } from '@app/core/services/friend.service';
import { FriendAPI } from '@app/lib/api/friend/api.friend.model';
import { FRIEND_ACTIONS, FRIEND_LIMITED_OF_USER, PRIVACIES, TAGS } from '@app/shared/constant';
import { switchMap, take } from 'rxjs';
import { CustomData } from '../badge-icon/badge-icon.component';

@Component({
  selector: 'friend-action-button',
  templateUrl: './friend-action-button.component.html',
  styleUrls: ['./friend-action-button.component.scss']
})
export class FriendActionButtonComponent {
  @Input() item: any;
  @Input() index: number;
  @Input() isProfile = false;
  @Input() isFriendLimit = false;
  @Output() friendAction = new EventEmitter();
  infoItem: CustomData;
  isShowOverlay = false;
  loading = false;
  relationshipStatusEnum = RelationshipStatusEnum;
  showFollowButton = false;

  TITLE_FRIEND_ACTIONS: any[] = [];
  friendRequestActions: any[] = [];
  currentAction: any;

  constructor(
    private friendService: FriendService,
    private router: Router,
    private followService: FollowerService
  ) {}

  ngOnInit() {
    this.infoItem = {
      id: this.item.id,
      name: this.item.full_name,
      avatarUrl: this.item.avatar_thumbnail_url,
      type: {
        name: TAGS.PEOPLE,
        role: null
      }
    };

    this.friendRequestActions = [
      {
        icon: 'sctr-icon-user-x-011 text-base',
        label: 'COMMON.BUTTON_ACTION.DECLINE',
        style: this.isProfile
          ? 'text-palette-gray-700 border-palette-gray-300 hover:bg-palette-gray-200 border whitespace-nowrap bg-palette-base-white'
          : 'text-palette-red-500 hover:bg-palette-gray-200 border-none bg-palette-base-white',
        action: () => {
          this.handleFriendshipAction(RelationshipStatusEnum.UnKnow, FRIEND_ACTIONS.DECLINE);
          this.isShowOverlay = false;
          this.loading = true;
        }
      },
      {
        icon: 'sctr-icon-user-check-011 text-base',
        label: 'COMMON.BUTTON_ACTION.ACCEPT',
        style: this.isProfile
          ? 'border bg-palette-blue-dark-400 hover:bg-palette-blue-dark-500 border-palette-blue-dark-400 text-palette-base-white'
          : 'text-palette-gray-700 hover:bg-palette-gray-200 border-none bg-palette-base-white',
        action: () => {
          this.handleFriendshipAction(RelationshipStatusEnum.FriendShip);
          this.isShowOverlay = false;
          this.loading = true;
        }
      }
    ];

    this.handleSetCurrentAction();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['item'] && changes['item'].currentValue) {
      if (!this.TITLE_FRIEND_ACTIONS.length) {
        this.handleInitActions();
      }
      this.loading = false;
      this.handleSetCurrentAction();
    }

    if (changes['isFriendLimit'] && changes['isFriendLimit'].currentValue) {
      if (this.TITLE_FRIEND_ACTIONS.length) {
        this.handleSetActionWhenLimitedFriend();
      }
    }
  }

  get isShowCTAButton() {
    if (
      this.item.follow_privacy?.toLowerCase() === PRIVACIES.public.toLowerCase() ||
      this.item.friend_request_privacy?.toLowerCase() === PRIVACIES.public.toLowerCase()
    ) {
      return true;
    }

    return this.item.follow_privacy?.toLowerCase() === PRIVACIES.friends.toLowerCase() && this.item.is_friend;
  }

  get isFriendRequestPrivate() {
    return this.item.friend_request_privacy?.toLowerCase() === PRIVACIES.private.toLowerCase() && !this.isSentRequest();
  }

  get isFriendLimitReached() {
    const totalFriend =
      this.item.number_of_friend +
      this.item.number_of_received_friend_invitation +
      this.item.number_of_sent_friend_invitation;
    return totalFriend >= FRIEND_LIMITED_OF_USER;
  }

  handleSetActionWhenLimitedFriend() {
    const indexNeedUpdate = this.TITLE_FRIEND_ACTIONS.findIndex(item => item.status === RelationshipStatusEnum.UnKnow);
    this.TITLE_FRIEND_ACTIONS[indexNeedUpdate] = {
      ...this.TITLE_FRIEND_ACTIONS[indexNeedUpdate],
      label: 'COMMON.FOLLOW',
      action: () => {
        this.loading = true;
        this.followService.followFanpage('user', this.item.id).subscribe(res => {
          if (res.success) {
            const itemUpdated = {
              ...this.item,
              relationship_status: [RelationshipStatusEnum.UnKnow, RelationshipStatusEnum.Following]
            };
            this.friendAction.emit(itemUpdated);
          }
          this.loading = false;
        });
      }
    };
    this.handleSetCurrentAction();
  }

  handleInitActions() {
    this.TITLE_FRIEND_ACTIONS = [
      {
        status: RelationshipStatusEnum.SentRequest,
        label: 'COMMON.CANCEL_REQUEST',
        action: () => {
          this.loading = true;
          this.handleFriendshipAction(RelationshipStatusEnum.UnKnow, FRIEND_ACTIONS.CANCEL);
        }
      },
      {
        status: RelationshipStatusEnum.UnKnow,
        label: this.isFriendLimitReached || this.isFriendRequestPrivate ? 'COMMON.FOLLOW' : 'COMMON.ADD_FRIEND',
        action: () => {
          this.loading = true;
          if (this.isFriendLimitReached || this.isFriendRequestPrivate) {
            this.followService.followFanpage('user', this.item.id).subscribe(res => {
              if (res.success) {
                const itemUpdated = {
                  ...this.item,
                  relationship_status: [RelationshipStatusEnum.UnKnow, RelationshipStatusEnum.Following]
                };
                this.friendAction.emit(itemUpdated);
              }
              this.loading = false;
            });
          } else {
            this.friendService.postFriendRequest(this.infoItem.id).subscribe(data => {
              if (data.success) {
                const itemUpdated = {
                  ...this.item,
                  relationship_status: RelationshipStatusEnum.SentRequest
                };
                this.friendAction.emit(itemUpdated);
              }
              this.loading = false;
            }, take(1));
          }
        }
      },
      {
        status: RelationshipStatusEnum.ReceivedRequest,
        label: 'COMMON.BUTTON_ACTION.RESPONSE',
        action: () => {
          this.isShowOverlay = true;
        }
      },
      {
        status: RelationshipStatusEnum.FriendShip,
        label: 'ENTITY.FRIEND',
        action: () => {
          this.router.navigateByUrl('/personal-profile/' + this.item.id);
        }
      },
      {
        status: RelationshipStatusEnum.Following,
        label: 'COMMON.UNFOLLOW',
        action: () => {
          this.loading = true;
          this.followService.unFollowFanpage('user', this.item.id).subscribe(res => {
            if (res.success) {
              const itemUpdated = {
                ...this.item,
                relationship_status: RelationshipStatusEnum.UnKnow
              };
              this.friendAction.emit(itemUpdated);
            }
            this.loading = false;
          });
        }
      }
    ];
  }

  handleSetCurrentAction() {
    if (this.item.relationship_status.includes(RelationshipStatusEnum.SentRequest)) {
      this.currentAction = this.TITLE_FRIEND_ACTIONS.find(el => el.status === RelationshipStatusEnum.SentRequest);
      return;
    }
    if (this.item.relationship_status.includes(RelationshipStatusEnum.FriendShip)) {
      this.currentAction = this.TITLE_FRIEND_ACTIONS.find(el => el.status === RelationshipStatusEnum.FriendShip);
      return;
    }
    if (this.item.relationship_status.includes(RelationshipStatusEnum.ReceivedRequest)) {
      this.currentAction = this.TITLE_FRIEND_ACTIONS.find(el => el.status === RelationshipStatusEnum.ReceivedRequest);
      return;
    }
    if (this.item.relationship_status.includes(RelationshipStatusEnum.Following)) {
      this.currentAction = this.TITLE_FRIEND_ACTIONS.find(el => el.status === RelationshipStatusEnum.Following);
      return;
    }
    this.currentAction = this.TITLE_FRIEND_ACTIONS.find(el => el.status === RelationshipStatusEnum.UnKnow);
  }

  onClickButton(): void {
    switch (this.item.relationship_status) {
      case RelationshipStatusEnum.SentRequest:
        this.loading = true;
        this.handleFriendshipAction(RelationshipStatusEnum.UnKnow, FRIEND_ACTIONS.CANCEL);
        break;
      case RelationshipStatusEnum.UnKnow:
        this.loading = true;
        this.friendService.postFriendRequest(this.infoItem.id).subscribe(data => {
          if (data.success) {
            const itemUpdated = {
              ...this.item,
              relationship_status: RelationshipStatusEnum.SentRequest
            };
            this.friendAction.emit(itemUpdated);
          }
          this.loading = false;
        }, take(1));
        break;
      case RelationshipStatusEnum.ReceivedRequest:
        this.isShowOverlay = true;
        break;
      case RelationshipStatusEnum.FriendShip:
        this.router.navigateByUrl('/personal-profile/' + this.item.id);
        break;
      default:
        break;
    }
  }

  handleFriendshipAction(relationship_status: string, action: string = FRIEND_ACTIONS.ACCEPT): void {
    this.friendService
      .getRelationshipStatus(this.infoItem.id)
      .pipe(
        switchMap((friendStatus: FriendAPI.Friend) => {
          switch (action) {
            case FRIEND_ACTIONS.CANCEL:
              return this.friendService.putCancelFriendRequest(friendStatus.data.friend_invitation_id!);
            case FRIEND_ACTIONS.DECLINE:
              return this.friendService.putDeclineFriendRequest(friendStatus.data.friend_invitation_id!);
            default:
              return this.friendService.putAcceptFriendRequest(friendStatus.data.friend_invitation_id!);
          }
        }),
        take(1)
      )
      .subscribe((res: any) => {
        if (res.success) {
          const itemUpdated = {
            ...this.item,
            relationship_status: [relationship_status]
          };
          this.friendAction.emit(itemUpdated);
        }
      });
  }

  isSentRequest() {
    return (
      this.item.relationship_status.includes(RelationshipStatusEnum.SentRequest) ||
      (this.item.relationship_status.includes(RelationshipStatusEnum.Following) &&
        this.item.relationship_status.includes(RelationshipStatusEnum.UnKnow))
    );
  }
}
