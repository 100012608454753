<div class="divTopFooter">
  <div
    *ngIf="isShowCreateAdsBtn"
    class="flex w-full text-base px-4 py-3 flex-col justify-center items-end gap-[10px] rounded-none border-y border-palette-gray-200">
    <p-button
      [label]="'ADVERTISING.CREATE.CREATE_ADS_FANPAGE' | translate"
      (click)="handleCreateAds()"
      [styleClass]="
        'w-full px-4 py-2 rounded-lg text-palette-base-white text-base font-semibold bg-branding-primary-700'
      ">
    </p-button>
  </div>
  <div class="flex justify-between py-3 px-4" *ngIf="totalReactions > 0 || totalComments > 0 || totalShares > 0 || totalCoins > 0">
    <div class="flex space-x-3.5" *ngIf="totalReactions > 0">
      <div class="flex items-center">
        <div
          *ngFor="let item of reactionList; let i = index"
          class="relative -mr-1.5 cursor-pointer"
          [ngClass]="getZIndexReaction(i)">
          <img
            *ngIf="i < 3"
            src="{{ item.icon }}"
            class="w-5"
            [pTooltip]="getReactedUsers(item.type)"
            [tooltipPosition]="isPostView ? 'right' : 'top'"
            [escape]="false"
            (mouseover)="getReactionsByEntityId(item.type)" />
        </div>
      </div>
      <div
        class="cursor-pointer text-palette-gray-500 hover:underline"
        [pTooltip]="getReactedUsers()"
        [tooltipPosition]="isPostView ? 'right' : 'top'"
        [escape]="false"
        (mouseover)="getReactionsByEntityId()"
        (click)="displayPopupViewReaction()">
        {{ totalReactions }}
      </div>
    </div>
    <div class="flex text-sm justify-self-end ml-auto">
      <ng-container *ngIf="totalCoins > 0">
        <div class="text-palette-gray-500">{{ "XU.NUMBER_XU" | translate:{number: totalCoins} }}</div>
        <span *ngIf="totalComments > 0 || totalShares > 0" class="px-1 text-palette-gray-500">&#x2022;</span>
      </ng-container>
      <ng-container *ngIf="totalComments > 0">
        <div
          (mouseover)="getCommenterListByEntityId()"
          [pTooltip]="commenterList"
          [tooltipPosition]="isPostView ? 'left' : 'top'"
          [escape]="false"
          class="cursor-pointer hover:underline text-palette-gray-500"
          (click)="showComment(true)">
          {{ totalComments }} {{ (totalComments === 1 ? 'ENTITY.COMMENT' : 'ENTITY.COMMENTS') | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="totalShares > 0">
        <span *ngIf="totalComments > 0" class="px-1 text-palette-gray-500">&#x2022;</span>
        <div
          (mouseover)="getShareListByEntityId()"
          [tooltipPosition]="'top'"
          [pTooltip]="shareList"
          [escape]="false"
          class="cursor-pointer hover:underline text-palette-gray-500">
          {{ totalShares }} {{ (totalShares === 1 ? 'ENTITY.SHARE' : 'ENTITY.SHARES') | translate }}
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!isPostReview" class="flex border-t relative" [ngClass]="isPostView ? 'xs:px-2 gap-2' : 'xs:px-6 gap-4'">
    <div
      class="h-14 py-2"
      [ngClass]="{
        'w-full': buttonList.length === 1,
        'w-1/3': buttonList.length === 3,
        'w-1/4': buttonList.length === 4
      }">
      <button
        pButton
        [loading]="loadingBtnReaction"
        (mouseenter)="showHideOverlayIcons(true)"
        checkAuthentication
        (openLoginPopup)="isOpenPopupLogin = $event"
        (mouseleave)="showHideOverlayIcons(false)"
        (mousedown)="showHideOverlayIcons(true, true)"
        (click)="
          !isOpenPopupLogin && selectedEmoji
            ? executedEmojiChanged('', true)
            : executedEmojiChanged(emojiList[0].type, true)
        "
        class="text-palette-gray-500 border-0 w-full h-full flex justify-center items-center space-x-1 md:space-x-2 hover:bg-palette-gray-100 focus:shadow-none max-xs:flex-col"
        [ngClass]="[selectedEmoji ? 'bg-palette-gray-100' : 'bg-white whitespace-nowrap']">
        <img
          *ngIf="!loadingBtnReaction"
          src="{{ selectedEmoji?.icon ? selectedEmoji.icon : buttonList[0].icon }}"
          class="w-5 h-5" />
        <span *ngIf="!isPostView"
          class="text-sm xs:text-base font-medium"
          [ngStyle]="{ 'color': selectedEmoji?.color ? selectedEmoji?.color : '' }"
          >{{ selectedEmoji?.label ? (selectedEmoji?.label | translate) : (buttonList[0].label | translate) }}</span
        >
      </button>
      <div class="{{ overlayVisible ? 'w-[296px] absolute h-11' : 'absolute' }}">
        <icon-popup
          (emojiChanged)="executedEmojiChanged($event)"
          (closePopup)="showHideOverlayIcons($event)"
          [selectedEmoji]="selectedEmoji"
          [overlayVisible]="overlayVisible"></icon-popup>
      </div>
    </div>
    <ng-container *ngFor="let item of buttonList">
      <div
        *ngIf="item.type !== 1"
        class="h-14 py-2"
        [ngClass]="{ 'w-1/3': buttonList.length === 3, 'w-1/4': buttonList.length === 4, 'w-1/5': buttonList.length === 5 }">
        <button
          pButton
          checkAuthentication
          (openLoginPopup)="isOpenPopupLogin = $event"
          [disabled]="item.type === 4 && data!.user_id === userInfo!.id"
          [ngClass]="
            item.type === 4 && data!.user_id === userInfo!.id
              ? ''
              : 'hover:bg-palette-gray-100 hover:text-gray-700 focus:shadow-none'
          "
          class="text-palette-gray-500 border-0 w-full h-full flex max-xs:flex-col justify-center items-center {{
            (item.type === 2 && hasCommented) || (item.type === 4 && hasGiftedCoin) ? 'bg-palette-gray-100' : 'bg-white'
          }} whitespace-nowrap space-x-1 md:space-x-2"
          (click)="executeButton(item.type, $event)">
          <img src="{{ item.icon }}" class="w-5 h-5" />
          <span *ngIf="!isPostView" class="text-sm xs:text-base font-medium">{{ item.label | translate: { syntax: '' } }}</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div class="border-t py-3 px-4" *ngIf="isShowComment && !isPostViewComment">
  <comment
    [post]="data"
    [isPostView]="isPostView"
    [isPostDetail]="isPostDetail"
    [pageSize]="pageSizeComment"
    [postOwnerId]="data.user_object.id"
    [totalCommentsAndReplies]="totalComments"
    [hideCommentBox]="hideCommentBox"
    (totalCommentsAndRepliesChange)="updateTotalComment($event)"
    (createdComment)="hasCommented = true"
    (isShowComment)="showComment($event)"></comment>
</div>

<p-menu #menu [model]="shareOptions" [popup]="true" [appendTo]="appendTo ? appendTo : 'body'"></p-menu>

<dialog-list-reaction
  [(visible)]="visible"
  [itemsTabMenu]="itemsTabMenu"
  [activeItemTabMenu]="activeItemTabMenu"
  [userInfo]="userInfo"
  [data]="data"
  [selectedEmoji]="selectedEmoji"></dialog-list-reaction>

<div *ngIf="isOpenPopupLogin">
  <popup-login [(visible)]="isOpenPopupLogin"></popup-login>
</div>
