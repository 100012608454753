<ng-container *ngIf="!(loadingHighlightsLibrary$ | async); else loadingAnimation">
  <div class="libraryTitle flex items-center justify-between">
    <h3 class="text-lg font-semibold text-palette-gray-700">{{ libraryTitle }}</h3>
    <button
      type="button"
      title="Go to library"
      (click)="onSwitchingLibraryTab()"
      class="w-7 aspect-square flex items-center justify-center rounded-md hover:bg-palette-gray-200 focus:ring-2 ring-branding-primary-300 ring-offset-2">
      <i class="sctr-icon-chevron-right text-xl"></i>
    </button>
  </div>
  <ng-container *ngIf="((highlightsLibrary$ | async) ?? []).length; else noHighlightMedia">
    <ng-container
      *ngIf="((highlightsLibrary$ | async) ?? []).length === 1"
      [ngTemplateOutlet]="highlightLibrary"
      [ngTemplateOutletContext]="{
        classGridCols: 'grid-cols-1',
        classImage: 'w-full aspect-video',
        skipFileIndex: -1
      }"></ng-container>
    <ng-container
      *ngIf="[2, 3].includes(((highlightsLibrary$ | async) ?? []).length)"
      [ngTemplateOutlet]="highlightLibrary"
      [ngTemplateOutletContext]="{
        classGridCols: 'grid-cols-2',
        classImage: 'w-full aspect-video',
        skipFileIndex: 2
      }"></ng-container>
    <ng-container
      *ngIf="[4, 5].includes(((highlightsLibrary$ | async) ?? []).length)"
      [ngTemplateOutlet]="highlightLibrary"
      [ngTemplateOutletContext]="{
        classGridCols: 'grid-cols-2',
        classImage: 'w-full aspect-video',
        skipFileIndex: 4
      }"></ng-container>
    <ng-container
      *ngIf="((highlightsLibrary$ | async) ?? []).length >= maxHighlightMediaItems"
      [ngTemplateOutlet]="highlightLibrary"
      [ngTemplateOutletContext]="{
        classGridCols: 'grid-cols-3',
        classImage: 'w-full aspect-square',
        skipFileIndex: -1
      }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #loadingAnimation>
  <div class="libraryTitle flex items-center justify-between">
    <loading-skeleton [rectSkeleton]="true" [rectSkeletonWidth]="'245px'" [rectSkeletonHeight]="'137px'">
    </loading-skeleton>
  </div>
</ng-template>

<ng-template
  #highlightLibrary
  let-classGridCols="classGridCols"
  let-classImage="classImage"
  let-skipFileIndex="skipFileIndex">
  <div class="grid {{ classGridCols }} gap-1 mt-3">
    <div *ngFor="let img of (highlightsLibrary$ | async) ?? []; index as index" class="">
      <ng-container *ngIf="index !== skipFileIndex">
        <img
          defaultImage
          src="{{ baseURL + img.uris.thumbnail }}"
          alt="{{ img.original_name }}"
          class="{{ classImage }} object-cover rounded border border-palette-gray-200 cursor-pointer"
          (click)="onDisplayGalleria(index)" />
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #noHighlightMedia>
  <div class="gap-1 mt-3 flex justify-center">
    <p-button
      label="Upload Photo/Video"
      styleClass="bg-white text-base font-semibold text-palette-gray-700 border border-palette-gray-300"
      (onClick)="fileInput.click()">
      <input #fileInput type="file" accept="image/*, video/*" multiple (change)="onNewFileSelected($event)" hidden
    /></p-button>
  </div>
</ng-template>

<ng-container>
  <div>
    <p-galleria
      [value]="(highlightsLibrary$ | async) ?? []"
      [(visible)]="isViewMedia"
      [containerStyle]="{ 'max-width': '90%' }"
      [numVisible]="7"
      [(activeIndex)]="galleriaActiveIndex"
      [circular]="true"
      [fullScreen]="true"
      [showItemNavigators]="true"
      [showThumbnails]="false"
      (visibleChange)="onVisibleChange($event)">
      <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="checkFileType(item.type, 'image')">
          <img
            defaultImage
            [src]="baseURL + item.uris.web"
            class="w-full max-h-[80vh] rounded-sm"
            alt="{{ item.original_name }}"
            loading="lazy" />
        </ng-container>
        <ng-container *ngIf="checkFileType(item.type, 'video')">
          <video
            [src]="baseURL + item.uris.web"
            id="videoGalleria"
            controls
            class="w-full max-h-[80vh] rounded-sm"></video>
        </ng-container>
      </ng-template>
      <ng-container></ng-container>
      <ng-template pTemplate="footer">
        <ng-container *ngIf="((highlightsLibrary$ | async) ?? [])[galleriaActiveIndex].description">
          <p
            class="bg-[rgba(234,236,240,0.9)] mt-1.5 p-1.5 rounded-sm text-sm font-semibold text-palette-gray-900 text-center">
            {{ ((highlightsLibrary$ | async) ?? [])[galleriaActiveIndex].description }}
          </p>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center max-w-[70px]">
          <img
            defaultImage
            [src]="baseURL + item.uris.thumbnail"
            alt="{{ item.original_name }}"
            class="aspect-square object-cover rounded" />
        </div>
      </ng-template>
    </p-galleria>
  </div>
</ng-container>
