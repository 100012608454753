import { Component, Input, OnInit } from '@angular/core';
import { checkUrl, FILE_TYPE_URL, POLL_VOTING_TYPE } from '@app/shared/models/post';

@Component({
  selector: 'post-content-poll-leader',
  templateUrl: './post-content-poll-leader.component.html',
  styleUrls: ['./post-content-poll-leader.component.scss']
})
export class PostContentPollLeaderComponent implements OnInit {
  @Input() data: any;
  @Input() isAllowViewVoteResult = false;

  leaderBoard: any;
  votingType = POLL_VOTING_TYPE;

  ngOnInit(): void {
    this.leaderBoard = this.getTop3(this.data.poll_result);
  }

  validUrl(url: string) {
    return `${checkUrl(url, FILE_TYPE_URL.web)}.webp`;
  }

  getTop3(pollResult: any) {
    const sortedResults = [...pollResult].sort((a, b) => {
      if (this.data.voting_type === this.votingType.COINS) {
        if (b.value === a.value) {
          return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
        }
        return b.value - a.value;
      } else {
        if (b.votes === a.votes) {
          return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
        }
        return b.votes - a.votes;
      }
    });

    const top1 = sortedResults[0] || null;
    const top2 = sortedResults[1] || null;
    const top3 = sortedResults[2] || null;

    return {
      top1,
      top2,
      top3
    };
  }
}
