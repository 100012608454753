import { Directive, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { PollService } from '@app/core/services/poll.service';
import { checkUrl, FILE_TYPE_URL } from '../models/post';

@Directive({
  selector: '[seoInfo]'
})
export class SeoInfoDirective {
  @Input() seoData: any;

  constructor(
    private metaService: Meta,
    private pollService: PollService
  ) {}

  ngOnInit(): void {
    if (this.seoData.poll_id) {
      this.pollService.getPollData(this.seoData.poll_id).subscribe(res => {
        if (res.success) {
          this.updateMeta(res.data.title, res.data.description ? res.data.description : res.data.title, res.data.background_image || '');
        }
      });
      return;
    }
    this.updateMeta(this.seoData.content, this.seoData.content, this.seoData.media_urls?.media[0]?.thumbnail);
  }

  updateMeta(title: string, description: string, imgUrl: string) {
    if (title && title !== '') {
      this.metaService.updateTag({ name: 'title', content: this.getFirstString(title) });
    }
    if (description && description !== '') {
      this.metaService.updateTag({ name: 'description', content: this.getFirstString(description) });
      this.metaService.updateTag({ property: 'og:description', content: this.getFirstString(description)});
      this.metaService.updateTag({ name: 'twitter:description', content: this.getFirstString(description)});
    }
    if (imgUrl) {
      this.metaService.updateTag({ name: 'image', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ property: 'og:image', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ property: 'og:image:secure_url', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ name: 'twitter:image', content: this.getImageUrl(imgUrl) });
      this.metaService.updateTag({ property: 'og:image:type', content: 'image/png' });
    }
  }

  getImageUrl(imgId: string) {
    return `${checkUrl(imgId, FILE_TYPE_URL.thumbnail)}.png`;
  }

  getFirstString(description: string): string {
    if (!description) return '';
    if (description.split(' ').length > 10) {
      const wordsArray = description.split(/\s+/);  // Regular expression for splitting by spaces
      return wordsArray.slice(0, 10).join(' ');
    }
    return description;
  }
}
