export const environment = {
  production: false,
  APP_NAME: 'Soctrip',
  COMPANY_NAME: 'Hahalolo Inc',
  baseURL: 'https://api.stagingsoctrip.com',
  SOCIAL_APP_URL: 'https://stagingsoctrip.com',
  TRAVEL_ASSIST_ORIGIN: 'https://tour.stagingsoctrip.com',
  TRAVEL_ASSIST_URL: 'https://tour.stagingsoctrip.com/tour',
  BOOKING_URL: 'https://hotel.stagingsoctrip.com',
  ECOMMERCE_FOOD_URL: 'https://food.stagingsoctrip.com',
  ECOMMERCE_URL: 'https://shop.stagingsoctrip.com',
  ECOMMERCE_FOOD_MGMT_URL: 'https://food-management.stagingsoctrip.com',
  ECOMMERCE_MGMT_URL: 'https://ecommerce-management.stagingsoctrip.com',
  ECOMMERCE_MEGALIVE_URL: 'https://mega-live.stagingsoctrip.com',
  ECOMMERCE_MEGALIVE_CENTRE_URL: 'https://mega-live-centre.stagingsoctrip.com',
  ECOMMERCE_HC_SPECIAL_SELLER_URL: 'https://home-connect-special-seller.stagingsoctrip.com',
  ECOMMERCE_HC_SUPPLIER_URL: 'https://home-connect-supplier.stagingsoctrip.com',
  CAR_RENTAL_ORIGIN: 'https://car.stagingsoctrip.com',
  CAR_RENTAL_URL: 'https://car.stagingsoctrip.com/booking/car',
  FLIGHT_URL: 'https://flight.stagingsoctrip.com',
  SOCTRIP_CENTER_URL: 'https://business-central.stagingsoctrip.com',
  CONTENT_APPROVAL_URL: 'https://approval.stagingsoctrip.com',
  AFFILIATE_URL: 'https://travel-affiliate.stagingsoctrip.com',
  MY_WALLET_URL: 'https://my-wallet.stagingsoctrip.com',
  VOUCHER_HUB_URL: 'https://voucher-hub.stagingsoctrip.com',
  FLIGHT_ADMIN_URL: 'https://flight-admin.stagingsoctrip.com',
  HOTEL_ADMIN_URL: 'https://hotel-admin.stagingsoctrip.com',
  PAYMENT_URL: 'https://payment.stagingsoctrip.com',
  SOCIAL_APP_DEEPLINK: 'stagingsoctrip://',
  APP_DEBUG_URL: '',
  TOKEN_KEY: 'access_token',
  OWNER_USER_ID: 'owner_user_id',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status',
  USER_PROFILE: 'user_profile',
  firebase: {
    apiKey: 'AIzaSyDDl3R28XfWQoJzWaPhf3WCLygQy6BhdyQ',
    authDomain: 'soctrip-app.firebaseapp.com',
    projectId: 'soctrip-app',
    storageBucket: 'soctrip-app.appspot.com',
    messagingSenderId: '179622932533',
    appId: '1:179622932533:web:8858907f49d6517ba1c17c',
    measurementId: 'G-TS6Z00VMFE'
  },
  languages: ['en', 'vi'],
  socialAuthenIds: {
    google: '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
    facebook: '2619489051654463'
  },
  API_TIMEOUT: 30000,
  ADMIN_CONTACT_EMAIL: 'support@soctrip.com',
  ANDROID_DOWNLOAD: 'https://play.google.com/store/apps/details?id=com.soctrip.social',
  IOS_DOWNLOAD: 'https://testflight.apple.com/join/6RARZ3xh',
  FILE_SIZE_LIMITS: {
    default: 5242880, //5MB
    image: 10485760, //10MB
    video: 209715200, //200MB
    pdf: 5242880
  },
  AD_BUFFER_SIZE_LIMIT: 2048, //2MB
  URL_WHITELIST: ['https://rtc.dayconyeu.com'],
  GA_CODE: 'G-PQBCBB9BDP'
};
