import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FILE_EXTENSION } from '@app/shared/constant';
import Hls from 'hls.js';

@Directive({
  selector: '[appHlsVideo]',
})
export class HlsVideoDirective implements OnInit, OnDestroy {
  @Input('appHlsVideo') src = ''; // Video source URL

  private hls: Hls | null = null;

  constructor(private el: ElementRef<HTMLVideoElement>) {}

  ngOnInit(): void {
    const videoElement = this.el.nativeElement;

    if (Hls.isSupported()) {
      this.hls = new Hls();

      // Load the HLS source and attach it to the video element
      this.hls.loadSource(this.src);
      this.hls.attachMedia(videoElement);

      this.hls.on(Hls.Events.ERROR, () => {
        if (this.src.startsWith('blob:')) {
          videoElement.src = this.src;
        } else {
          videoElement.src = this.src + FILE_EXTENSION.video;
        }
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // Fallback for browsers with native HLS support (e.g., Safari)
      videoElement.src = this.src;
    }
  }

  ngOnDestroy(): void {
    if (this.hls) {
      this.hls.destroy();
    }
  }
}