<ng-container [formGroup]="formAnswers">
  <ng-container formArrayName="answers">
    <ng-container *ngFor="let item of answerBlock.controls; let i = index">
      <div class="flex flex-row gap-2 mb-3 items-start">
        <!-- <i class="sctr-icon-dots-grid text-lg"></i> -->

        <div class="bg-[#EAECF0] rounded-xl w-full p-1">
          <div [formGroupName]="i" id="link-{{ i }}" class="p-inputgroup items-center link-container flex gap-1">
            <div class="relative">
              <ng-container *ngIf="!uploadedImages[i]; else showUploadedImages">
                <label
                  class="h-[90px] w-24 flex flex-col items-center justify-center gap-1 px-2 rounded-xl md:border md:border-dashed md:border-palette-gray-300 cursor-pointer bg-palette-base-white hover:border-branding-primary-600">
                  <div
                    class="flex w-fit h-fit items-center justify-center p-2 bg-[#D1E9FF] rounded-[28px] border-[4px] border-solid border-palette-gray-50">
                    <i class="sctr-icon-upload-cloud-02 w-4 h-4 text-branding-primary-600"></i>
                  </div>
                  <input [disabled]="isSubmitting" type="file" (change)="handleFileInput($event, i)" hidden />
                </label>
              </ng-container>

              <ng-template #showUploadedImages>
                <img
                  [src]="uploadedImages[i]"
                  class="h-[90px] w-[125px] object-cover rounded-xl"
                  alt="Uploaded Image" />
                <button
                  [disabled]="isSubmitting"
                  (click)="removeImage(i)"
                  class="absolute top-1 right-1 px-[7px] py-[2px] bg-[#00000099] rounded-full">
                  <i class="sctr-icon-trash-03 text-xs text-palette-base-white"></i>
                </button>
              </ng-template>
            </div>

            <textarea
              formControlName="name"
              class="w-full h-full rounded-md"
              rows="3"
              [autoResize]="true"
              placeholder="{{ 'POLL.OPTION' | translate }}"
              pInputTextarea>
            </textarea>
          </div>
        </div>

        <i
          *ngIf="!isEdit || editData?.status !== pollStatus.ONGOING || (editData?.status === pollStatus.ONGOING && !answerBlock.value[i].block)"
          (click)="removeOption(i)"
          class="sctr-icon-trash-03 text-lg"
          [ngClass]="this.answerBlock.length === 2 ? 'text-palette-gray-400' : 'cursor-pointer'"></i>
      </div>
    </ng-container>

    <div>
      <button
        (click)="addOption()"
        [disabled]="isSubmitting"
        class="bg-palette-base-white border p-2 rounded-lg hover:bg-palette-gray-200 border-branding-primary-600 text-branding-primary-600 disabled:bg-palette-gray-100 disabled:border-palette-gray-100 disabled:text-palette-gray-300">
        {{ 'LIVESTREAM.ADD_OPTION' | translate }}
      </button>
    </div>
  </ng-container>
</ng-container>
