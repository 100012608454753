import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { SignUpBannerModel, UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError, map, retry, timeout } from 'rxjs/operators';
import { ApiClientConfig } from '../api-client.config';
import { errorHandler } from '../api-error';
import { UserAPI, UserAvatarResponse, UserFrameResponse } from '../user/api.user.model';

export class UserApi {
  private apiUrl: string = environment.baseURL;
  USER = '/user';
  TWO_FA = '/twofa';
  CHANGE_PASSWORD = '/password';
  PRIVACY_SETTINGS = '/privacy-settings';
  SEARCH_V2 = '/search-v2';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) {}

  deactivateUser(body: UserAPI.ReasonToLeave) {
    return this.http.put<any>(this.apiUrl + '/user/me/deactivate', body).pipe(
      retry(1),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getUserInfo(userId: string): Observable<UserInfo> {
    return this.http.get<UserInfo>(this.apiUrl + `/user/users/${userId}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  signUp(data: UserAPI.SignUp): Observable<UserAPI.SignUp> {
    return this.http.post<UserAPI.SignUp>(`${this.apiUrl}${this.USER}/signup`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  thirdPartySignUp(data: UserAPI.SocialUser): Observable<UserAPI.SocialUser> {
    return this.http.post<UserAPI.SocialUser>(`${this.apiUrl}${this.USER}/third-party/signup`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  verifyCode(data: UserAPI.VerifyCode): Observable<UserAPI.VerifyCode> {
    return this.http.post<UserAPI.VerifyCode>(`${this.apiUrl}${this.USER}/check-verification-code`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => errorHandler(error))
    );
  }

  sendCode(data: UserAPI.SendCode): Observable<UserAPI.SendCode> {
    return this.http.post<UserAPI.SendCode>(`${this.apiUrl}${this.USER}/send-verification-code`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  sendOTPCode(data: UserAPI.SendCode): Observable<UserAPI.SendCode> {
    return this.http.post<UserAPI.SendCode>(`${this.apiUrl}${this.USER}/send-otp`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  verifyOTPCode(data: UserAPI.VerifyCode): Observable<UserAPI.VerifyCode> {
    return this.http.post<UserAPI.VerifyCode>(`${this.apiUrl}${this.USER}/verify-otp`, data).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  setNewPassword(data: UserAPI.SetNewPassWord): Observable<UserAPI.SetNewPassWord> {
    return this.http.post<UserAPI.SetNewPassWord>(`${this.apiUrl}${this.USER}/set-new-password`, data).pipe(
      map((res: any) => res),
      catchError(error => {
        throw error;
      })
    );
  }

  uploadUserAvatar(path: string): Observable<string> {
    const avatarPath = { avatar_thumbnail_url: path };
    const url = `${this.apiUrl}/user/me/avatar`;
    return this.http.put<UserAvatarResponse>(url, avatarPath).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  uploadUserFrame(path: string): Observable<string> {
    const url = `${this.apiUrl}/user/me/frame-url`;
    const frame_url = { frame_url: path };
    return this.http.put<UserFrameResponse>(url, frame_url).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteUserFrame(): Observable<any> {
    const url = `${this.apiUrl}/user/me/frame-url`;
    return this.http.delete<any>(url).pipe(
      timeout(this.config.responseTimeout),
      catchError(error => {
        throw error.error;
      })
    );
  }

  confirmPassword(password: string): Observable<boolean> {
    const body = {
      confirm_password: `${password}`
    };
    return this.http.post<boolean>(`${this.apiUrl}${this.USER}/confirm-password`, body).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res.data ? res.data : false;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  blockUser(userId: string): Observable<any> {
    return this.http.post(`${this.apiUrl}${this.USER}/${userId}/block`, {}).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  unblockUser(userId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}${this.USER}/${userId}/unblock`, {}).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  isBlockingUser(userId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}${this.USER}/${userId}/is-blocking`, {}).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getBlockingList(pageNum: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.apiUrl}${this.USER}/blocking-list?pageNum=${pageNum}&pageSize=${pageSize}`, {}).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  isBlockedUser(userId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}${this.USER}/${userId}/is-blocked`, {}).pipe(
      timeout(this.config.responseTimeout),
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  deleteUser(body: UserAPI.ReasonToLeave): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}${this.USER}/me/delete`, { body }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  undeleteUser(): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}${this.USER}/me/undelete`, {}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getTwoFaCode(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.TWO_FA}/authenticator`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  setUpTwoFaCode(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.TWO_FA}/authenticator`, {}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  removeTwoFa(otp: string): Observable<any> {
    const body = { otp };
    return this.http.delete<any>(`${this.apiUrl}${this.TWO_FA}/authenticator`, { body }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  verifyTwoFaOtp(body: UserAPI.VerifyOTPTwoFa): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.TWO_FA}/authenticator/validate`, body).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  changePassword(payload: UserAPI.ChangePassword): Observable<any> {
    return this.http.put(`${this.apiUrl}${this.USER}${this.CHANGE_PASSWORD}`, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getRecoveryCode(): Observable<UserAPI.RecoveryCode> {
    return this.http.get<UserAPI.RecoveryCode>(`${this.apiUrl}${this.TWO_FA}/recovery-code`).pipe(
      map((res: UserAPI.RecoveryCode) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  generateRecoveryCode(): Observable<UserAPI.RecoveryCode> {
    return this.http.post<UserAPI.RecoveryCode>(`${this.apiUrl}${this.TWO_FA}/recovery-code`, {}).pipe(
      map((res: UserAPI.RecoveryCode) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getRecoveryCodeFile(language: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get<Blob>(`${this.apiUrl}${this.TWO_FA}/download/recovery-codes`, {
        observe: 'response',
        params: { language },
        responseType: 'blob' as 'json'
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  putUserPrivacySettings(value: string, setting_type: string) {
    return this.http
      .put<UserAPI.RecoveryCode>(
        `${this.apiUrl}/user${this.PRIVACY_SETTINGS}?value=${value}&setting_type=${setting_type}`,
        {}
      )
      .pipe(
        map((res: UserAPI.RecoveryCode) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  hideMessage(): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/user/me/hide-message`, null).pipe(
      catchError(error => {
        throw error.error;
      })
    );
  }

  removeUserAvatar(): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/user/me/avatar`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getUserV2(getUserV2Params: UserAPI.GetUserV2Params | any): Observable<any> {
    const params = new HttpParams().appendAll(getUserV2Params);
    return this.http
      .get<any>(`${this.apiUrl}${this.USER}${this.SEARCH_V2}`, {
        params
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getBonusModalInfo(language: string): Observable<SignUpBannerModel> {
    return this.http.get<SignUpBannerModel>(this.apiUrl + `/user/${language}/sign-up-bonus-modal`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  requiresGuidance(): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/user/me/requires-guidance`, null).pipe(
      catchError(error => {
        throw error.error;
      })
    );
  }
}
