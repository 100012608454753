import { FRIEND_LIMITED_OF_USER } from '@app/shared';
import { UserInfo } from '@app/shared/models/user';
import { createReducer, on } from '@ngrx/store';
import { BlockUserActions, BlockUserApiActions, UserInfoActions, UserInfoApiActions } from './users.actions';
import { BlockListState, BlockUserState, UserInfoState } from './users.state';

const initUserInfoState: UserInfoState = {
  loadingUserInfo: false,
  userInfo: {} as UserInfo,
  bloomStringData: {
    bloomStringComment: '',
    bloomStringCoin: '',
    blockedBloomString: '',
    blockingBloomString: ''
  },
  currentRegion: ''
};

const initBlockUserState: BlockUserState = {
  loadingBlockUser: false,
  data: {
    id: '',
    userId: '',
    userBlockedId: '',
    is_active: false
  }
};

const initManageBlockState: BlockListState = {
  loadingBlockList: false,
  data: {
    page: 0,
    page_size: 0,
    total_element: 0,
    data: []
  }
};

export const userInfoReducer = createReducer(
  initUserInfoState,
  on(UserInfoActions.onGetUserInfo, state => ({
    ...state,
    loadingUserInfo: true
  })),

  on(UserInfoApiActions.onGetUserInfoSuccess, (state, { userInfo }) => ({
    ...state,
    loadingUserInfo: false,
    userInfo: userInfo.data,
    isFriendLimit:
      userInfo.data.number_of_friend +
        userInfo.data.number_of_sent_friend_invitation +
        userInfo.data.number_of_received_friend_invitation >=
      FRIEND_LIMITED_OF_USER
  })),

  on(UserInfoActions.onUpdatePropertyUserInfo, (state, { property }) => ({
    ...state,
    userInfo: { ...state.userInfo, ...property }
  })),

  on(UserInfoActions.onUpdateUserName, (state, { first_name, last_name }) => ({
    ...state,
    loadingUserInfo: false,
    userInfo: { ...state.userInfo, first_name, last_name, full_name: `${first_name} ${last_name}` }
  })),

  on(UserInfoActions.onUpdateAvatarUserInfo, (state, { avatar_thumbnail_url }) => ({
    ...state,
    loadingUserInfo: false,
    userInfo: { ...state.userInfo, avatar_thumbnail_url }
  })),

  on(UserInfoActions.onUpdateFrameUserInfo, (state, { frame_url }) => ({
    ...state,
    loadingUserInfo: false,
    userInfo: { ...state.userInfo, frame_url }
  })),

  on(UserInfoApiActions.onGetCommentBloomFilterSuccess, (state, { bloomString }) => ({
    ...state,
    loadingUserInfo: false,
    bloomStringData: {
      ...state.bloomStringData,
      bloomStringComment: bloomString
    }
  })),

  on(UserInfoApiActions.onGetCoinBloomFilterSuccess, (state, { bloomString }) => ({
    ...state,
    loadingUserInfo: false,
    bloomStringData: {
      ...state.bloomStringData,
      bloomStringCoin: bloomString
    }
  })),

  on(UserInfoActions.onGetBlockedUserBloomFilter, (state, { bloomString }) => ({
    ...state,
    bloomStringData: {
      ...state.bloomStringData,
      blockedBloomString: bloomString
    }
  })),

  on(UserInfoActions.onGetBlockingUserBloomFilter, (state, { bloomString }) => ({
    ...state,
    bloomStringData: {
      ...state.bloomStringData,
      blockingBloomString: bloomString
    }
  })),

  on(UserInfoActions.onGetCurrentRegion, (state, { countryCode }) => ({
    ...state,
    currentRegion: countryCode
  }))
);

export const blockUserReducer = createReducer(
  initBlockUserState,
  on(BlockUserActions.onBlockUser, state => ({
    ...state,
    loadingBlockUser: true
  })),
  on(BlockUserApiActions.onBlockUserSuccess, (state, { blockData }) => ({
    ...state,
    loadingBlockUser: false,
    data: blockData.data
  })),
  on(BlockUserApiActions.onBlockUserFail, state => ({
    ...state,
    loadingBlockUser: false
  })),

  on(BlockUserActions.onUnblockUser, state => ({
    ...state,
    loadingBlockUser: true
  })),
  on(BlockUserApiActions.onUnblockUserSuccess, (state, { blockData }) => ({
    ...state,
    loadingBlockUser: false,
    data: blockData.data
  })),
  on(BlockUserApiActions.onUnblockUserFail, state => ({
    ...state,
    loadingBlockUser: false
  }))
);
export const manageBlockReducer = createReducer(
  initManageBlockState,
  on(BlockUserActions.onGetBlockingList, state => ({
    ...state,
    loadingBlockList: true
  })),
  on(BlockUserApiActions.onGetBlockingListSuccess, (state, { blockingList }) => ({
    ...state,
    loadingBlockList: false,
    data: {
      page: blockingList.data.page,
      page_size: blockingList.data.page_size,
      total_element: blockingList.data.total_element,
      data: [...(state.data.data ?? []), ...(blockingList.data.data ?? [])]
    }
  })),
  on(BlockUserApiActions.onGetBlockingListFail, state => ({
    ...state,
    loadingBlockList: false
  })),

  on(BlockUserActions.onDeleteItemBlockList, (state, { blockId }) => {
    const updatedContent = (state.data.data || []).filter((friend: any) => friend.id !== blockId);
    const updatedFriendsState = {
      ...state.data,
      total_element: state.data.total_element - 1,
      data: updatedContent
    };
    return { ...state, data: updatedFriendsState };
  }),

  on(BlockUserActions.onDestroyBlockUser, state => ({
    ...state,
    loadingBlockList: false,
    data: {
      page: 0,
      page_size: 0,
      total_element: 0,
      data: []
    }
  })),

  on(BlockUserActions.onUpdateItemBlockList, (state, { blockId, key, value }) => {
    const updatedContent = (state.data.data || []).map((friend: any) =>
      friend.id === blockId ? { ...friend, [key]: value } : friend
    );
    const updatedFriendsState = {
      ...state.data,
      data: updatedContent
    };
    return { ...state, data: updatedFriendsState };
  })
);
