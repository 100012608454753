import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'errorPipe',
    pure: false
})
export class ErrorPipe implements PipeTransform {
    constructor(private translateService: TranslateService) { }

    transform(value: any) {
        const detectParams = this.detectParams(this.translateService.instant(value.split('|')[0]));
        
        if(!value.startsWith('ERROR.')){
            return value;
        }
        if (detectParams.length > 0 ) {
            return this.checkParam(value.split('|')[0], value.split('|').slice(1), detectParams);
        }

        return this.translateService.instant(value.split('|')[0]);
        
    }

    private checkParam(error: string, param: any, detectParams: string[]) {        
        const paramMap: { [key: string]: string } = {};

        for (let i = 0; i < param.length; i++) {
            paramMap[`${detectParams[i]}`] = param[i];
        }
        return this.translateService.instant(error, paramMap);
    }
    private detectParams(template: string): string[] {
        const regex = /{{\s*(\w+)\s*}}/g;
        const params: string[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(template)) !== null) {
            params.push(match[1]);
        }
        return params;
    }
}