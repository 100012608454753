import { Router } from '@angular/router';
import { CheckInAPI } from '@app/lib/api/check-in/api.check-in.model';
import { MediaApiModels } from '@app/lib/api/media/api.media.models';
import { PollObject } from '@app/lib/api/poll/api.poll.model';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { TYPE_TAB_VIEW_ITEMS } from '../constant';
import { GroupDetailModel } from './group.model';
import { UserInfo } from './user';

export interface Post {
  id: string;
  comments?: Comment[];
  content: string;
  friend_tags?: FriendTag[];
  group_object?: GroupDetailModel;
  location_id?: string;
  media_urls?: MediaInfo;
  page?: PostPage;
  post_type: string;
  posted_date: string;
  title?: string;
  user_object: UserInfo;
  user_id?: string;
  total_comments?: number;
  total_shares?: number;
  total_reactions?: number;
  total_coins?: number;
  total_reaches?: number;
  post_privacy: string;
  parent?: Post;
  post_pattern: string;
  list_reactions?: Reaction[];
  post_feeling?: string;
  library_object?: MediaApiModels.AlbumWithMedia;
  share_link_type?: string;
  object_data?: LinkInfo;
  share_link?: string;
  group_id?: string;
  page_id?: string;
  page_object?: PageObject;
  livestream_info?: LivestreamInfo;
  message_ranges?: MessageRanges[];
  check_in?: CheckInAPI.CheckInResponse;
  action_type?: string;
  ad_name?: string;
  button_name?: string;
  description?: string;
  destination_url?: string;
  object_id?: string;
  object_type?: string;
  post_id?: string;
  status: string;
  post_info?: Post;
  updated_at: string;
  ads_id?: string;
  post_category?: string;
  archived_date?: string;
  system_moderate?: boolean;
  parent_moderate?: boolean;
  is_archive?: boolean;
  user_gender?: string;
  poll_object?: PollObject;
  poll_id?: string;
  coin_object?: {
    object_id: string;
    total_amount: number;
  };
  pin_position?: number;
}

export const POLL_STATUS = {
  INCOMING: 'INCOMING',
  ONGOING: 'ONGOING',
  END: 'END'
};

export const POLL_TYPE = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE'
};

export const POLL_VOTING_TYPE = {
  NORMAL: 'NORMAL',
  COINS: 'COINS'
};

export const POLL_PRIVACIES = {
  activities: 'ACTIVITIES',
  result: 'RESULT',
  resultPercents: 'RESULT_PERCENTS'
};

export const POLL_MODE = {
  default: 'DEFAULT',
  presentation: 'PRESENTATION'
};

export const POLL_INFO_TYPE = {
  notStart: 'NOT_START',
  reachMaxVote: 'REACH_MAX_VOTE',
  reachLimitVote: 'REACH_LIMIT_VOTE',
  pollRules: 'POLL_RULES'
};

export interface PageObject {
  avatar: string;
  cover_image_uri: string;
  link: string;
  page_id: string;
  page_name: string;
  is_verified: boolean;
  user_name: string;
  city_code: string;
}

export interface LivestreamInfo {
  id: string;
  url: string;
  title: string;
  description: string;
}
export interface LinkInfo {
  title: string;
  description?: string;
  array_images?: string[];
  avatar: string;
  price?: number;
  original_price?: number;
  price_unit?: string;
  rating?: number;
  hashtag?: string;
  total_reviews?: number;
  sub_title?: string;
}

export interface PostEditNormal {
  title?: string;
  content?: string;
  post_type: string;
  post_privacy: string;
  group_id?: string;
  page_id?: string;
  location_id?: string;
  friend_tags: any[];
  media_urls?: MediaInfo;
  post_feeling?: string;
}

export interface PostEditShare {
  title?: string;
  content?: string;
  post_privacy: string;
  location_id?: string;
  friend_tags: any[];
  post_feeling?: string;
}

export interface MediaComment {
  id: string;
  type: string;
  extension: string;
  original_name: string;
  signature: string;
}

export interface PostGroup { }

export interface PostPage { }

export interface MediaInfo {
  media: Media[];
  layout_type: string;
}

export interface Media {
  type: string;
  thumbnail: string;
  original: string;
  upload?: boolean;
  is_loading?: boolean;
}

export interface MediaTour {
  type: string;
  id: string;
  extension: string;
}

export interface Share {
  user_object: UserInfo;
  content?: string;
  share_date: string;
}

export interface HeaderPost extends Post {
  shareToGroupInfo?: GroupDetailModel;
  shareToFeedInfo?: ShareToFeedInfo;
  shareType?: number;
  post_info?: Post;
  ads_id?: string;
}

export interface FriendTag {
  user_object: UserInfo;
  user_id: string;
  status?: string;
}

export interface ShareToFeedInfo {
  id: string;
  full_name: string;
}

export interface DropdownOption {
  label: string;
  value: any;
  image: string;
  action: () => void;
}

export interface ReactionInfo {
  id: string;
  entity_id: string;
  entity_type: string;
  reaction_type: string;
  created_by: UserInfo;
  updated_at: string;
}

export interface MyReaction {
  id: string;
  entity_id: string;
  reaction_type: string;
  updated_at: string;
}

export interface MyReactionsInfo {
  expiry: string;
  oldestTime: string;
  reactionList: MyReaction[];
}

export const DagObjectBookmark = 'POST_BOOKMARK';

export enum ReactionType {
  love = 'LOVE',
  haha = 'HAHA',
  wow = 'WOW',
  sad = 'SAD',
  angry = 'ANGRY',
  all = 'ALL' // Key name of all reactions for reacted user array
}

export const EmojiList = [
  {
    type: ReactionType.love,
    icon: 'assets/emoji/M - Icon - Heart.svg',
    largeIcon: 'assets/emoji/L - Icon - Heart.svg',
    selectedIcon: 'assets/emoji/L - Icon - Heart - Dot.svg',
    color: '#F63D68',
    label: 'POST.LOVE'
  },
  {
    type: ReactionType.haha,
    icon: 'assets/emoji/M - Icon - Haha.svg',
    largeIcon: 'assets/emoji/L - Icon - Haha.svg',
    selectedIcon: 'assets/emoji/L - Icon - Haha - Dot.svg',
    color: '#EAAA08',
    label: 'POST.HAHA'
  },
  {
    type: ReactionType.wow,
    icon: 'assets/emoji/M - Icon - Wow.svg',
    largeIcon: 'assets/emoji/L - Icon - Wow.svg',
    selectedIcon: 'assets/emoji/L - Icon - Wow - Dot.svg',
    color: '#EAAA08',
    label: 'POST.WOW'
  },
  {
    type: ReactionType.sad,
    icon: 'assets/emoji/M - Icon - Sad.svg',
    largeIcon: 'assets/emoji/L - Icon - Sad.svg',
    selectedIcon: 'assets/emoji/L - Icon - Sad - Dot.svg',
    color: '#EAAA08',
    label: 'POST.SAD'
  },
  {
    type: ReactionType.angry,
    icon: 'assets/emoji/M - Icon - Angry.svg',
    largeIcon: 'assets/emoji/L - Icon - Angry.svg',
    selectedIcon: 'assets/emoji/L - Icon - Angry - Dot.svg',
    color: '#F04438',
    label: 'POST.ANGRY'
  }
];

export interface PostAction {
  icon: string;
  label: string;
  action?: any;
}

export const actionsPost = {
  delete: 'DELETE_POST',
  download_image_or_video: 'DOWNLOAD_IMAGE_OR_VIDEO',
  edit: 'EDIT_POST',
  report: 'REPORT_POST',
  edit_privacy: 'EDIT_PRIVACY',
  bookmark: 'BOOKMARK_POST',
  pin_post: 'PIN_POST',
  unpin: 'UNPIN_POST',
  hide: 'HIDE_POST',
  archive: 'ARCHIVE_POST',
  unfollow: 'UNFOLLOW',
  follow: 'FOLLOW',
  remove_tag: 'REMOVE_TAG'
};

export interface Reaction {
  type: string;
  icon?: string;
  count: number;
  users?: string[];
}

export const IndexTypes = {
  advanced: 'z-[5]',
  high: 'z-[4]',
  intermediate: 'z-[3]',
  moderate: 'z-[2]',
  low: 'z-[1]'
};

export const ReactionZIndex = (index: number) => {
  switch (index) {
    case 0:
      return IndexTypes.advanced;
    case 1:
      return IndexTypes.high;
    case 2:
      return IndexTypes.intermediate;
    case 3:
      return IndexTypes.moderate;
    case 4:
      return IndexTypes.low;
    default:
      return;
  }
};

export const DROPDOWN_POST = {
  default: 1,
  download: 2,
  timeline: 3,
  fanpagePostInSearch: 4,
  groupAdmin: 5,
  groupAdminPostOwner: 6,
  groupMember: 7,
  groupMemberPostOwner: 8,
  pinedPost: 9,
  post_owner: 10,
  fanpageAdminOwner: 11,
  fanpageEditorNotPostAuthor: 12,
  fanpageEditorAndPostAuthor: 13,
  fanpageBasicOptions: 14,
  postTagged: 15,
  fanpagePinedPostAuthor: 16,
  fanpagePinedPostAdminOwner: 17
};

export const SHARE_TYPE = {
  feed: 1,
  group: 2
};
export const ProgressBar = {
  value_10: 10,
  value_20: 20,
  value_30: 30,
  value_40: 40,
  value_50: 50,
  value_60: 60,
  value_70: 70,
  value_80: 80,
  value_90: 90,
  value_100: 100
} as const;

export const ENTITY_TYPE = {
  post: 'POST',
  comment: 'COMMENT',
  story: 'STORY',
  livestream: 'LIVESTREAM',
  media: 'MEDIA'
};

export const checkUrl = (urlString: string, style: string) => {
  if (urlString && (urlString.includes('http:') || urlString.includes('https:'))) {
    return urlString;
  }
  if (urlString && urlString.includes('/storage/files/')) {
    return environment.baseURL + urlString;
  } else return `${environment.baseURL}/storage/files/${style}/${urlString}`;
};

export const FILE_TYPE_URL = {
  thumbnail: 'thumbnail',
  web: 'web',
  mobile: 'mobile',
  streamVideo: 'stream-video'
};
export interface MessageRanges {
  id?: string;
  entity_id: string;
  entity_type: string;
  length: number;
  offset: number;
  full_name?: string;
}

export interface QuickReportRequest {
  [key: string]: any;
  reactions?: boolean;
  comments?: boolean;
  shares?: boolean;
  orders?: boolean;
  coins?: boolean;
  new_friends?: boolean;
  view_profile?: boolean;
  mentioned?: boolean;
  time_range_type: string;
}
export interface QuickReportModel {
  [key: string]: any;
  start_date: string;
  end_date: string;
  VIEW_PROFILE?: number;
  COIN?: number;
  NEW_FRIEND?: number;
  COMMENT?: number;
  REACTION?: number;
  MENTION?: number;
  ORDER?: number;
  SHARE?: number;
  time_range_type: string;
}

export const getFromNow = (date: string) => {
  return moment(date)
    .locale(JSON.parse(localStorage.getItem('user_profile') || '{}').language || 'en')
    .fromNow();
};

export const dateArraySort = (arr: Post[], newItem: Post) => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (new Date(arr[mid].posted_date) > new Date(newItem.posted_date)) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }

  arr.splice(left, 0, newItem);

  return arr;
};

export const reIndexPinPosition = (posts: Post[]) => {
  // Reindex positions, skipping objects without a `pin_position` property
  let position = 1;
  const newsPosts = posts.map(post => {
    if (!!post?.pin_position) {
      return { ...post, pin_position: position++ };
    }
    return post;
  });

  return newsPosts;
};

export const reIndexUnpinPost = (posts: Post[], postIndex: number) => {
  //Cloned array for remove pin_position
  const newPosts = posts.map(obj => ({ ...obj }));
  newPosts[postIndex].pin_position = undefined;

  const nullPositionPosts = newPosts.filter((post, index) => post?.pin_position === undefined || index === postIndex);
  const pinedPosts = newPosts.filter(post => post?.pin_position !== undefined);
  //Sort post not have pin_position
  nullPositionPosts.sort((a, b) => Date.parse(b.posted_date) - Date.parse(a.posted_date));
  return [...reIndexPinPosition(pinedPosts), ...nullPositionPosts];
};

export interface Access {
  name: string;
  icon: string;
  color: string;
  href?: string;
  index: number;
  image?: string;
  action: () => void;
}
export interface QuickAccess {
  title: string;
  accesses: Access[];
}

export const listQuickAccess = (router: Router, userId?: string, isLoggedIn?: boolean): Access[] => {
  return [
    {
      name: 'HEADER.MEGA_MENU.VIDEO',
      icon: 'sctr-icon-solid-play-circle',
      color: 'icon-watch',
      href: `watch`,
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/watch']);
        }
      },
      index: 6
    },
    {
      name: 'ENTITY.FRIENDS',
      icon: 'sctr-icon-solid-users-03',
      color: 'icon-friend',
      href: `personal-profile/${userId}?Tab=${TYPE_TAB_VIEW_ITEMS.FRIENDS.TITLE}`,
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/personal-profile/' + userId], {
            queryParams: { Tab: TYPE_TAB_VIEW_ITEMS.FRIENDS.TITLE },
            queryParamsHandling: 'merge'
          });
        }
      },
      index: 0
    },
    {
      name: 'ENTITY.GROUPS',
      icon: 'sctr-icon-solid-users-03',
      color: 'icon-group',
      href: `personal-profile/${userId}?Tab=${TYPE_TAB_VIEW_ITEMS.GROUPS.TITLE}`,
      image: '/assets/icons/sctr-icon-solid-users-04.svg',
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/personal-profile/' + userId], {
            queryParams: { Tab: TYPE_TAB_VIEW_ITEMS.GROUPS.TITLE },
            queryParamsHandling: 'merge'
          });
        }
      },
      index: 1
    },
    {
      name: 'COMMON.SAVED',
      icon: 'sctr-icon-solid-bookmark-check',
      color: 'icon-saved',
      href: `${environment.TRAVEL_ASSIST_URL}/saved-list`,
      action: () => {
        if (isLoggedIn) {
          window.location.href = environment.TRAVEL_ASSIST_URL + '/saved-list';
        }
      },
      index: 2
    },
    {
      name: 'ENTITY.LIVESTREAMS',
      icon: 'sctr-icon-solid-circle',
      color: 'icon-livestream',
      href: `livestream`,
      image: '/assets/icons/Record.svg',
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/livestream']);
        }
      },
      index: 3
    },
    {
      name: 'ENTITY.FANPAGES',
      icon: 'sctr-icon-solid-file-heart-03',
      color: 'icon-fanpage',
      href: `personal-profile/${userId}?Tab=${TYPE_TAB_VIEW_ITEMS.FANPAGES.TITLE}`,
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/personal-profile/' + userId], {
            queryParams: { Tab: TYPE_TAB_VIEW_ITEMS.FANPAGES.TITLE },
            queryParamsHandling: 'merge'
          });
        }
      },
      index: 4
    },
    {
      name: 'HEADER.MEGA_MENU.ALBUMS',
      icon: 'sctr-icon-solid-image-01',
      color: 'icon-albums',
      href: `personal-profile/${userId}?Tab=${TYPE_TAB_VIEW_ITEMS.LIBRARY.TITLE}`,
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/personal-profile/' + userId], {
            queryParams: { Tab: TYPE_TAB_VIEW_ITEMS.LIBRARY.TITLE },
            queryParamsHandling: 'merge'
          });
        }
      },
      index: 5
    },
    {
      name: 'ENTITY.ADS_CENTER',
      icon: '',
      color: '',
      href: '/advertisement/campaign',
      image: '/assets/icons/ads_center.svg',
      action: () => {
        if (isLoggedIn) {
          router.navigate(['/advertisement/campaign']);
        }
      },
      index: 7
    }
  ];
};
