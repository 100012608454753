import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { SignUpBannerModel } from '@app/shared/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'banner-signup',
  templateUrl: './banner-signup.component.html',
  styleUrls: ['./banner-signup.component.scss']
})
export class BannerSignupComponent {
  @Input() visible = false;
  @Output() visibleChange = new EventEmitter();
  title = '';
  description = '';

  constructor(
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userService
      .getBonusModalInfo(this.translateService.currentLang || 'en')
      .subscribe((res: SignUpBannerModel) => {
        this.title = res.data.title;
        this.description = res.data.description;
      });
  }

  closePopup() {
    this.visible = false;
    this.visibleChange.emit(false);
  }
}
