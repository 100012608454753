import { Component } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { environment } from '@env/environment';

export const WELCOME_IMAGES = new Map<string, string>([
  ['vi', '/assets/images/soctrip-welcome-vn.png'],
  ['en', '/assets/images/soctrip-welcome-en.png']
]);

@Component({
  selector: 'authen-container',
  templateUrl: './authen-container.component.html',
  styleUrls: ['./authen-container.component.scss']
})
export class AuthenContainerComponent {
  imageUrl = WELCOME_IMAGES.get('vi');
  appName = environment.APP_NAME;
  changeImageInterval: NodeJS.Timeout;

  constructor(private TranslationService: TranslationService) {}

  // TODO: Temporarily comment out the code following new update from the customer.
  // ngOnInit(): void {
  //   // this.changeImage();
  //   // this.changeImageInterval = setInterval(() => this.changeImage(), 5000);
  // }

  // ngOnDestroy() {
  //   clearInterval(this.changeImageInterval);
  // }

  // changeImage(): void {
  //   this.imageUrl = this.loginService.getImageAndText();
  // }

  ngOnInit(): void {
    this.onLanguageChanged();
  }

  onLanguageChanged() {
    this.TranslationService.language$.subscribe(res => {
      if (res) {
        this.imageUrl = WELCOME_IMAGES.get(res);
      }
    });
  }
}
