import { Component, ViewEncapsulation } from '@angular/core';
import { TranslationService } from '@app/core/services/translation.service';
import { CommonActions } from '@app/modules/main/states/common-state/common-state.actions';
import { Store } from '@ngrx/store';
import { ShepherdService } from 'angular-shepherd';

@Component({
  selector: 'tour-guide',
  templateUrl: './tour-guide.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tour-guide.component.scss']
})
export class TourGuideComponent {
  private stepTimeout: any = null;
  steps: any[] = [];

  constructor(
    private shepherdService: ShepherdService,
    private translateService: TranslationService,
    private store: Store
  ) {}

  start() {
    this.initShepherdSteps();
    this.shepherdService.start();
  }

  ngOnInit() {
    this.handleTourGuidFinished();
  }

  getShepherdSteps(translate: TranslationService) {
    const baseModalClass = 'rounded-[12px] border border-[#EAECF0] bg-white w-[240px] h-[156px] ml-4 mt-2';
    const baseTextModal = (title: string, desc: string, step: number) => [
      `<div class="relative">
      <p class="self-stretch text-[#101828] font-['Inter'] text-[14px] font-semibold leading-[20px]">${translate.getTranslation(
        title
      )}</p>
      <p class="h-[60px] self-stretch text-[#667085] font-['Inter'] text-[14px] font-normal leading-[20px]">${translate.getTranslation(
        desc
      )}</p>
      <span class="absolute -bottom-9 text-base left-0 text-[#667085]">${step}/7</span>
      </div>`
    ];
    const baseBtnModal = (title: string, type: 'next' | 'cancel') => [
      {
        text: [`<p class="text-white font-semibold text-[14px] leading-[20px]">${translate.getTranslation(title)}</p>`],
        type,
        classes: 'flex justify-center items-center px-[8px] py-[4px] gap-[4px] rounded-[8px] bg-[#1570EF]'
      }
    ];

    this.steps = [
      {
        id: 'step-1',
        classes: baseModalClass,
        text: baseTextModal(
          'TOUR_DEMO_SIGNUP_USER.SOCTRIP_TRAVEL_SOCIAL_NETWORK',
          'TOUR_DEMO_SIGNUP_USER.A_PLACE_TO_SHARE_EXPERIENCES',
          1
        ),
        attachTo: { element: '#newsfeed-btn', on: 'right' },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.BUTTON_NEXT', 'next'),
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            this.store.dispatch(CommonActions.onInTourGuide({ isInTourGuide: true }));
            resolve();
          });
        },
        when: {
          show: () => this.handleMoveOnAfter5Seconds('next')
        }
      },
      {
        id: 'step-2',
        classes: baseModalClass,
        text: baseTextModal('HEADER.FLIGHTS', 'TOUR_DEMO_SIGNUP_USER.FLY_ANYWHERE', 2),
        attachTo: { element: '#flight-btn', on: 'right' },
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            this.store.dispatch(CommonActions.onHandleCurrentPage({ page: 'booking' }));
            resolve();
          });
        },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.BUTTON_NEXT', 'next'),
        when: {
          show: () => this.handleMoveOnAfter5Seconds('next')
        }
      },
      {
        id: 'step-3',
        classes: baseModalClass,
        text: baseTextModal('HEADER.HOTELS', 'TOUR_DEMO_SIGNUP_USER.DISCOVER_THOUSAND', 3),
        attachTo: { element: '#hotel-btn', on: 'right' },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.BUTTON_NEXT', 'next'),
        when: {
          show: () => this.handleMoveOnAfter5Seconds('next')
        }
      },
      {
        id: 'step-4',
        classes: baseModalClass,
        text: baseTextModal('HEADER.CARS', 'TOUR_DEMO_SIGNUP_USER.EASY_AND_COST_EFFECTIVE', 4),
        attachTo: { element: '#car-btn', on: 'right' },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.BUTTON_NEXT', 'next'),
        when: {
          show: () => this.handleMoveOnAfter5Seconds('next')
        }
      },
      {
        id: 'step-5',
        classes: baseModalClass,
        text: baseTextModal('HEADER.TOURS', 'TOUR_DEMO_SIGNUP_USER.REASONABLE_PRICES', 5),
        attachTo: { element: '#tour-btn', on: 'right' },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.BUTTON_NEXT', 'next'),
        when: {
          show: () => this.handleMoveOnAfter5Seconds('next')
        }
      },
      {
        id: 'step-6',
        classes: baseModalClass,
        text: baseTextModal(
          'TOUR_DEMO_SIGNUP_USER.BUTTON_FOOD',
          'TOUR_DEMO_SIGNUP_USER.ENJOY_UNIQUE_CULINARY_EXPERIENCES',
          6
        ),
        attachTo: { element: '#food-btn', on: 'right' },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.BUTTON_NEXT', 'next'),
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            this.store.dispatch(CommonActions.onInTourGuide({ isInTourGuide: false }));
            this.store.dispatch(CommonActions.onHandleCurrentPage({ page: 'food' }));
            resolve();
          });
        },
        when: {
          show: () => this.handleMoveOnAfter5Seconds('next')
        }
      },
      {
        id: 'step-7',
        classes: baseModalClass,
        text: baseTextModal('HEADER.MEGA_MENU.SHOPPING', 'TOUR_DEMO_SIGNUP_USER.SHOP_AFFORDABLY_WITH_SOCTRIP', 7),
        attachTo: { element: '#shopping-btn', on: 'right' },
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            this.store.dispatch(CommonActions.onHandleCurrentPage({ page: 'shopping' }));
            resolve();
          });
        },
        buttons: baseBtnModal('TOUR_DEMO_SIGNUP_USER.ENJOY', 'cancel'),
        when: {
          show: () => this.handleMoveOnAfter5Seconds('cancel')
        }
      }
    ];
    return this.steps;
  }

  initShepherdSteps() {
    this.shepherdService.defaultStepOptions = {
      classes: 'shadow-md bg-purple-dark'
    };
    this.shepherdService.modal = true;
    this.shepherdService.addSteps(this.getShepherdSteps(this.translateService));
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      bodyElement.classList.add('overflow-hidden');
    }
  }

  handleTourGuidFinished() {
    this.shepherdService.onTourFinish = event => {
      const hasCompletedTour = JSON.parse(localStorage.getItem('requires_guidance') || 'false');
      if (event === 'cancel' && !hasCompletedTour) {
        localStorage?.setItem('requires_guidance', JSON.stringify(true));
        const bodyElement = document.querySelector('body');
        if (bodyElement) {
          bodyElement.classList.remove('overflow-hidden');
        }
        this.store.dispatch(CommonActions.onHandleCurrentPage({ page: 'newsfeed' }));
        this.store.dispatch(CommonActions.onVisibleTourGuide({ visible: false }));
        this.store.dispatch(CommonActions.onCompletedTour());
      }
    };
  }

  handleMoveOnAfter5Seconds(type: 'next' | 'cancel') {
    if (this.stepTimeout) {
      clearTimeout(this.stepTimeout);
    }

    this.stepTimeout = setTimeout(() => {
      if (type === 'next') {
        this.shepherdService.next();
      } else if (type === 'cancel') {
        this.shepherdService.cancel();
      }
    }, 5000);
  }
}
