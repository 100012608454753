import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateFormatter } from './date-formatter.pipe';

@Pipe({
  name: 'ordinalDate',
  pure: false
})
export class OrdinalDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private customDateFormatter: CustomDateFormatter
  ) { }

  transform(startDate: string, endDate: string) {
    const language = this.translateService.currentLang || 'en';
    if (!startDate || !endDate) {
      return '';
    };
    if (language === 'vi') {
      return `ngày ${this.customDateFormatter.transform(startDate)} đến ${this.customDateFormatter.transform(endDate)}`;
    } else {
      return `${this.customDateFormatter.transform(startDate)} and ${this.customDateFormatter.transform(endDate)}`;
    }
  }
}
