import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { checkUrl, FILE_TYPE_URL, POLL_STATUS } from '@app/shared/models/post';

@Component({
  selector: 'post-dialog-poll-answers',
  templateUrl: './post-dialog-poll-answers.component.html',
  styleUrls: ['./post-dialog-poll-answers.component.scss']
})
export class PostDialogPollAnswersComponent {
  @Input() editData: any;
  @Input() isEdit: boolean;
  @Input() formAnswers: FormGroup;
  @Input() isSubmitting: boolean;
  @Output() updateValidators = new EventEmitter();

  pollStatus = POLL_STATUS;

  uploadedImages: string[] = [];
  uploadedImagesId: string[] = [];
  uploadedFiles: (File | null)[] = [];

  constructor(private fb: FormBuilder) { }

  get answerBlock() {
    return <FormArray>this.formAnswers.get('answers');
  }

  ngOnInit(): void {
    this.formAnswers = this.fb.group({
      answers: this.fb.array([this.createAnswer(), this.createAnswer()])
    });

    this.handleEditData(this.editData);
  }

  createAnswer(): FormGroup {
    let params: { [key: string]: FormControl } = {
      name: new FormControl('', Validators.required)
    };

    if (this.isEdit && this.editData?.status === this.pollStatus.ONGOING) {
      params['block'] = new FormControl(false);
    }

    return this.fb.group(params);
  }

  handleEditData(poll_object: any) {
    const poll_result =
      poll_object?.poll_result.map((item: any) => {
        this.uploadedImagesId.push(item.image || '');
        this.uploadedImages.push(item.image ? checkUrl(item.image, FILE_TYPE_URL.thumbnail) : '');
        return {
          answer_id: item.answer_id,
          name: item.name
        };
      }) || [];

    const answers = poll_result.length ? poll_result : [this.createAnswer(), this.createAnswer()];

    this.formAnswers = this.fb.group({
      answers: this.fb.array(
        answers.map((answer: any) => {
          let params: { [key: string]: FormControl } = {
            answerId: new FormControl(answer?.answer_id || ''),
            name: new FormControl(answer?.name || '', Validators.required)
          };

          if (this.isEdit && this.editData?.status === this.pollStatus.ONGOING) {
            params['block'] = new FormControl(true);
          }

          return this.fb.group(params);
        })
      )
    });
  }

  addOption(): void {
    this.answerBlock.push(this.createAnswer());
    this.uploadedImages.push('');
    this.updateValidators.emit();
  }

  removeOption(index: number): void {
    if (this.answerBlock.length > 2) {
      this.answerBlock.removeAt(index);
      this.uploadedImages.splice(index, 1);
      this.updateValidators.emit();
    }
  }

  removeImage(index: number): void {
    this.uploadedImages[index] = '';
    this.uploadedFiles[index] = null;
  }

  handleFileInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.uploadedFiles[index] = file;

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          this.uploadedImages[index] = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  enableForm(isClearAllImages = true): void {
    const answersArray = this.formAnswers.get('answers') as FormArray;
    answersArray.controls.forEach(control => control.enable());
    if (isClearAllImages) {
      this.uploadedFiles = [];
      this.uploadedImages = [];
    }
  }

  disableForm() {
    const answersArray = this.formAnswers.get('answers') as FormArray;
    answersArray.controls.forEach(control => control.disable());
  }

  get isValid(): boolean {
    return !this.formAnswers.valid || !this.formAnswers.value.answers[0].name.length;
  }

  get value(): any {
    return this.answerBlock.value;
  }

  get length(): any {
    return this.answerBlock.length;
  }
}
