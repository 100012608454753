<div class="container mb-[26px] relative z-[9999] xl:w-[567px] w-full max-xs:w-[280px]">
  <div #backdrop class="backdrop emoji" [ngClass]="{ 'text-center theme-applied content-center': theme }">
    <div #divEl class="highlights" [innerHTML]="highlightedText"></div>
  </div>
  <textarea
    [(ngModel)]="textValue"
    [attr.placeholder]="
      'POST.HI_SHARE_YOUR_FEEL' | translate: { userName: isFanpagePost ? fanpageInfo?.page_name : userInfo.first_name }
    "
    [maxlength]="30000"
    [ngClass]="{ 'text-center theme-applied content-center': theme }"
    (scroll)="handleScroll()"
    (keyup)="onKeyUp($event)"
    (ngModelChange)="this.onChangeContent.emit(textValue)"
    (paste)="onPaste($event)"
    (keydown)="onKeyDown($event)"
    (input)="updateCurrentCharacter()"
    class="{{ applyThemeStyles(themeBg) }} align-middle emoji"
    #textarea>
  </textarea>
  <p-overlayPanel
    #mentionOverlayPanel
    styleClass="before:content-[none] after:content-[none] mt-1 p-0 absolute bg-white border border-palette-gray-200 rounded-xl shadow !z-[10000]"
    [style]="{ 'top': overlayPosition.top + 'px', 'left': overlayPosition.left + 'px' }"
    appendTo="textarea">
    <div
      #mentionListContainer
      scroll
      (scrolledToBottom)="loadMoreMentionFriends()"
      class="h-fit max-h-[300px] flex flex-col gap-1 scroll-smooth overflow-y-auto -my-3 -mx-4 px-1">
      <ng-container *ngIf="!isLoadingMentionList && mentionList.length; else loadingMentionList">
        <div
          [id]="'mention-item-' + index"
          class="min-h-fit"
          *ngFor="let friend of mentionList; index as index"
          (click)="onSelectMentionFriend(friend)">
          <div
            class="flex items-center min-h-fit cursor-pointer rounded-lg p-1.5"
            [ngClass]="highlightedIndex === index ? 'bg-palette-blue-100' : 'hover:bg-palette-gray-100'">
            <avatar [isDisableNavigate]="true" [isSmallThumbnail]="true" [userInfo]="friend"></avatar>
            <span
              [title]="friend.full_name"
              class="px-2 font-semibold text-sm whitespace-nowrap overflow-hidden w-[200px] md:w-fit max-w-[200px] truncate select-none"
              #nameSpan
              >{{ friend.full_name }}
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoadingMoreMentionList" [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
      <ng-template #loadingMentionList>
        <ng-container [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
        <ng-container [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
        <ng-container [ngTemplateOutlet]="loadingMentionFriend"></ng-container>
      </ng-template>
    </div>
    <ng-template #loadingMentionFriend>
      <div class="flex items-center gap-2 min-h-fit p-1.5">
        <p-skeleton shape="circle" size="40px" />
        <p-skeleton width="9rem" height="20px" />
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel
    #hashtagOverlayPanel
    styleClass="before:content-[none] after:content-[none] mt-1 p-0 absolute bg-white border border-palette-gray-200 rounded-xl shadow !z-[10000]"
    [style]="{ 'top': overlayPosition.top + 'px', 'left': overlayPosition.left + 'px' }"
    appendTo="textarea">
    <div
      scroll
      #hashtagListContainer
      class="h-fit max-h-[300px] flex flex-col gap-1 scroll-smooth overflow-y-auto -my-3 -mx-4 px-1">
      <ng-container *ngIf="!isLoadingHashTagList && hashtagList.length; else loadingHashtagList">
        <div
          [id]="'hashtag-item-' + index"
          class="min-h-fit"
          *ngFor="let item of hashtagList; index as index"
          (click)="onSelectHashTag(item)">
          <div
            class="flex flex-col border-1 border-palette-gray-600 min-h-fit cursor-pointer rounded-lg p-1.5"
            [ngClass]="highlightedIndex === index ? 'bg-palette-blue-100' : 'hover:bg-palette-gray-100'">
            <span
              [title]="item.hashtag"
              class="font-semibold text-sm whitespace-nowrap overflow-hidden w-[200px] md:w-fit max-w-[200px] truncate select-none text-branding-primary-600"
              #nameHashtag
              >{{ item.hashtag }}
            </span>
            <span class="text-palette-gray-600 text-xs">{{ item.count + ' ' + ((item.count > 1 ? 'POST.POSTS' : 'POST.POST') | translate | lowercase) }}</span>
          </div>
        </div>
      </ng-container>
      <ng-template #loadingHashtagList>
        <ng-container [ngTemplateOutlet]="loadingHashtag"></ng-container>
        <ng-container [ngTemplateOutlet]="loadingHashtag"></ng-container>
        <ng-container [ngTemplateOutlet]="loadingHashtag"></ng-container>
      </ng-template>
    </div>
    <ng-template #loadingHashtag>
      <div class="flex items-center gap-2 min-h-fit p-1.5">
        <div class="flex flex-col gap-1">
          <p-skeleton width="8rem" height="20px" />
          <p-skeleton width="5rem" height="20px" />
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
