<div *ngIf="!item.relationship_status.includes(relationshipStatusEnum.MySelf) && isShowCTAButton" class="relative">
  <ng-container
    *ngIf="
      !(item.relationship_status.includes(relationshipStatusEnum.ReceivedRequest) && isProfile);
      else isReceivedRequest
    ">
    <button
      [disabled]="loading"
      [icon]="loading ? 'pi-spin pi sctr-icon-loading-02 mr-2' : ''"
      pButton
      class="p-button-sm whitespace-nowrap border-none h-fit text-palette-blue-700 bg-palette-blue-50 font-semibold"
      [ngClass]="{
        'text-palette-gray-700 bg-palette-gray-100': isSentRequest(),
        'pointer-events-none cursor-default': loading
      }"
      (click)="loading ? null : currentAction.action()">
      <i
        *ngIf="item.relationship_status.includes(relationshipStatusEnum.FriendShip)"
        class="sctr-icon-user-check-011 mr-1"></i>
      <span class="font-semibold">{{ currentAction.label | translate }}</span>
      <i
        *ngIf="item.relationship_status.includes(relationshipStatusEnum.ReceivedRequest)"
        class="sctr-icon-chevron-down ml-1"></i>
    </button>
    <p-overlay
      appendTo="body"
      *ngIf="isShowOverlay"
      [(visible)]="isShowOverlay"
      [contentStyleClass]="'border-round absolute top-2 right-0 h-fit w-max'">
      <div class="bg-palette-base-white rounded-lg drop-shadow-md py-2 px-[6px]">
        <ng-container [ngTemplateOutlet]="CTAButtons"></ng-container>
      </div>
    </p-overlay>
  </ng-container>

  <ng-template #isReceivedRequest>
    <div class="flex items-center justify-cente gap-2">
      <ng-container [ngTemplateOutlet]="CTAButtons"></ng-container>
    </div>
  </ng-template>
</div>

<ng-template #CTAButtons>
  <span *ngIf="loading" class="pi-spin pi sctr-icon-loading-02 mr-2"></span>
  <ng-container *ngIf="!loading">
    <button
      pButton
      *ngFor="let item of friendRequestActions"
      class="w-max h-fit flex items-center py-[9px] px-[10px]"
      [ngClass]="item.style"
      (click)="item.action()"
      icon="{{ !isProfile ? item.icon : '' }}">
      <span
        [ngClass]="{
          'ml-2': !isProfile
        }">
        {{ item.label | translate }}
      </span>
    </button>
  </ng-container>
</ng-template>
