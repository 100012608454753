<div class="relative w-full">
  <input
    #inputTime
    pInputText
    type="text"
    class="cursor-pointer w-full h-11 rounded-lg pl-10"
    [(ngModel)]="formattedSelectedTime"
    readonly
    [disabled]="disabled"
    placeholder="{{ 'TIME.TIME_PLACEHOLDER' | translate }}"
    (click)="visibleTimePicker = !visibleTimePicker" />
  <i class="sctr-icon-clock text-palette-gray-500 text-xl/5 absolute top-1/2 -translate-y-1/2 left-3"></i>
  <p-overlay
    [target]="inputTime"
    [(visible)]="visibleTimePicker"
    (onShow)="onVisibleChange(true)"
    appendTo="body"
    (onHide)="onVisibleChange(false)"
    (onBeforeShow)="setTemporaryTimePicker()"
    contentStyleClass="w-full py-1 bg-transparent">
    <div class="rounded-lg bg-white border border-palette-gray-200 shadow">
      <div class="grid grid-cols-3">
        <div class="p-2 flex flex-col gap-1 items-center border-r border-palette-gray-200 h-72 overflow-y-scroll">
          <ng-container *ngFor="let item of hours">
            <ng-container
              [ngTemplateOutlet]="timeValue"
              [ngTemplateOutletContext]="{
                context: item,
                selected: pickedHour && pickedHour.value === item.value
              }"></ng-container>
          </ng-container>
        </div>
        <div class="p-2 flex flex-col gap-1 items-center border-r border-palette-gray-200 h-72 overflow-y-scroll">
          <ng-container *ngFor="let item of minutes; index as index">
            <ng-container
              *ngIf="index % setMinutes === 0"
              [ngTemplateOutlet]="timeValue"
              [ngTemplateOutletContext]="{
                context: item,
                selected: pickedMinute && pickedMinute.value === item.value
              }"></ng-container>
          </ng-container>
        </div>
        <div class="p-2 flex flex-col gap-1 items-center">
          <ng-container *ngFor="let item of periods">
            <ng-container
              [ngTemplateOutlet]="timeValue"
              [ngTemplateOutletContext]="{
                context: item,
                selected: pickedPeriod && pickedPeriod.value === item.value
              }"></ng-container>
          </ng-container>
        </div>
      </div>
      <hr class="border-palette-gray-200" />
      <div class="flex justify-between text-sm font-semibold p-4">
        <div *ngIf="isShowNowBtn"
          (click)="setCurrentTime()"
          class="cta-button text-palette-blue-600 min-w-fit bg-white hover:bg-palette-blue-100 border-none">
          {{ 'EVENT.NOW' | translate }}
        </div>
        <div class="grow flex justify-end gap-3">
          <button
            (click)="cancelPickTime()"
            class="cta-button border-palette-gray-300 bg-white hover:bg-palette-gray-200 text-palette-gray-700 disabled:bg-white disabled:text-palette-gray-300">
            {{ 'COMMON.BUTTON_ACTION.CANCEL' | translate }}
          </button>
          <button
            *ngIf="pickedValidTime"
            (click)="handlePickedTime(); visibleTimePicker = false"
            class="cta-button text-white bg-branding-primary-600 border-branding-primary-600 hover:bg-branding-primary-700 disabled:bg-branding-primary-200 disabled:border-branding-primary-200">
            {{ 'COMMON.BUTTON_ACTION.APPLY' | translate }}
          </button>
        </div>
      </div>
    </div>
  </p-overlay>
</div>

<ng-template #timeValue let-context="context" let-selected="selected">
  <div
    (click)="onSelectTime(context)"
    class="w-full h-9 p-2 transition-colors flex justify-center items-center text-sm rounded cursor-pointer"
    [ngClass]="
      selected
        ? 'bg-palette-blue-600 hover:bg-palette-blue-700 text-white'
        : 'bg-white hover:bg-palette-gray-200 text-palette-gray-700'
    ">
    {{ context.label }}
  </div>
</ng-template>
