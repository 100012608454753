import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { ShareAPI } from '@app/lib/api/share/api.share.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharesService {
  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(this.http, { responseTimeout: environment.API_TIMEOUT });
  }

  getPostsShareByPostId(
    data: ShareAPI.RequestPostsShareByPostId
  ): Observable<ShareAPI.MultipleDataResponse<ShareAPI.PostShare>> {
    return this.api.share.getPostsShareByPostId(data);
  }
  
  getShareListByEntityId(
    object_id: string
  ): Observable<ShareAPI.MultipleDataResponse<ShareAPI.ShareList>> {
    return this.api.share.getShareListByEntityId(object_id);
  }

  countMySharesByListId(data: ShareAPI.RequestCountMySharesByListId): Observable<ShareAPI.SingleDataResponse<number>> {
    return this.api.share.countMySharesByListId(data);
  }
}
