import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PollService } from '@app/core/services/poll.service';
import { PollAPI, PollObject } from '@app/lib/api/poll/api.poll.model';
import { checkUrl, FILE_TYPE_URL, POLL_PRIVACIES, POLL_VOTING_TYPE } from '@app/shared/models/post';

interface AnswerActivities {
  answer_id: string;
  name: string;
  votes: string;
  value: number;
  details: any;
  showDetail: boolean;
  pageNum: number;
  totalElement: number;
}

@Component({
  selector: 'poll-details-dialog',
  templateUrl: './poll-details-dialog.component.html',
  styleUrls: ['./poll-details-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PollDetailsDialogComponent {
  @Input() visible = false;
  @Input() pollData: PollObject;
  @Input() isPollOwner = false;
  @Output() visibleChange = new EventEmitter();

  pollOverview: PollAPI.PollOverview[] = [];
  pollActivitiesArray: any[] = [];
  tableHeaders = ['USER', 'VOTE_TIME'];
  tableKeys = ['full_name', 'created_at'];
  votingType = POLL_VOTING_TYPE;
  intervalOverview: NodeJS.Timeout;
  isAllowViewActivitiesTab = false;
  isAllowViewVoteResult = false;

  constructor(private pollService: PollService) { }

  onShowDialog() {
    this.pollService.getPollOverview(this.pollData.id).subscribe(res => {
      if (res.success) {
        this.pollOverview = res.data;
        this.refreshOverview();
        if (this.pollOverview) {
          this.pollOverview.forEach((answer: any) => {
            this.pollActivitiesArray.push(this.createPollActivitiesData(answer));
          });
        }
      }
    });
    if (this.pollData.voting_type === this.votingType.COINS) {
      this.tableHeaders = ['USER', 'XU', 'VOTE_TIME'];
      this.tableKeys = ['full_name', 'value', 'created_at'];
    }

    this.isAllowViewActivitiesTab = this.pollData.display_privacies.includes(POLL_PRIVACIES.activities) || this.isPollOwner;
    this.isAllowViewVoteResult = this.pollData.display_privacies.includes(POLL_PRIVACIES.resultPercents) || this.pollData.display_privacies.includes(POLL_PRIVACIES.result) || this.isPollOwner;
  }

  refreshOverview() {
    this.intervalOverview = setInterval(() => {
      this.pollService.getPollOverview(this.pollData.id).subscribe(res => {
        if (res.success) {
          res.data.forEach((el, i) => {
            this.pollOverview[i].name = el.name;
            this.pollOverview[i].votes = el.votes;
            this.pollOverview[i].value = el.value;
            this.pollOverview[i].rate = el.rate;
            if (this.pollOverview[i].image !== el.image) this.pollOverview[i].image = el.image;
          })
        }
      });
    }, 3000);
  }

  onHideDialog() {
    clearInterval(this.intervalOverview);
    this.visibleChange.emit(false);
  }

  createPollActivitiesData(data: any): AnswerActivities {
    const { answer_id, name, votes, value } = data;
    return {
      answer_id,
      name,
      votes,
      value,
      details: null,
      showDetail: false,
      pageNum: 0,
      totalElement: 0
    };
  }

  onChangeDetailsSectionVisible(selectedAnswerId: string, checked: boolean): void {
    const newValue = !checked;
    const answerToUpdate = this.pollActivitiesArray.find(answer => answer?.answer_id === selectedAnswerId);
    answerToUpdate.showDetails = newValue;
    if (newValue && !answerToUpdate.details) {
      this.pollService
        .getPollActivities(this.pollData.id, selectedAnswerId, { pageSize: 10, pageNum: answerToUpdate.pageNum })
        .subscribe((res: any) => {
          (answerToUpdate.details = res?.data), (answerToUpdate.totalElement = res?.totalElement);
        });
    }
  }

  onPagingChange(event: any, selectedAnswerId: string) {
    const answerToUpdate = this.pollActivitiesArray.find(answer => answer?.answer_id === selectedAnswerId);
    if (answerToUpdate?.totalElement > answerToUpdate?.details?.length) {
      answerToUpdate.pageNum = this.getPageNum(event.first);
      this.pollService
        .getPollActivities(this.pollData.id, selectedAnswerId, { pageSize: 5, pageNum: answerToUpdate.pageNum })
        .subscribe(res => {
          answerToUpdate.details = [...answerToUpdate?.details, ...(res?.data as any)];
        });
    }
  }

  getPageNum = (first = 0): number => (first ? Math.floor(first / 5) : 0);

  validUrl(url: string) {
    return `${checkUrl(url, FILE_TYPE_URL.web)}.webp`;
  }
}
