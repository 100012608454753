<ng-container *ngIf="!loadingData">
    <div *ngFor="let group of monthGroups">
      <div class="grid w-full p-4 grid-cols-4 overflow-auto">
        <div class="col-span-1 font-semibold text-sm text-palette-gray-700">
          {{ translateMonth(group.month) }}
        </div>
        <div class="gap-y-2 rounded-lg bg-palette-gray-50 col-span-3">
          <div class="grid col-span-2" *ngFor="let item of group.items">
            <div class="p-3">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <div class="flex flex-col">
                    <div class="font-medium text-palette-gray-700 text-sm w-[340px]">
                      <div [innerHTML]="generateTitleTransaction(item)">
                      </div>
                    </div>
                    <div class="font-normal text-gray-500 text-sm">{{ item.created_at | dateFormatPipe: true }}</div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex justify-end gap-1 items-center">
                    <div
                      *ngIf="checkTransactionType(item.transaction_type)"
                      class="text-palette-orange-dark-400 font-medium text-end">
                      +{{ item.amount }}
                    </div>
                    <div
                      *ngIf="!checkTransactionType(item.transaction_type)"
                      class="text-palette-gray-700 font-medium text-end">
                      -{{ item.amount }}
                    </div>
                    <img
                      class="w-4 h-4"
                      *ngIf="checkTransactionType(item.transaction_type)"
                      src="assets/icons/coin.svg" />
                    <img
                      class="w-4 h-4 filter-gray"
                      *ngIf="!checkTransactionType(item.transaction_type)"
                      src="assets/icons/coin-gray.svg" />
                  </div>
                  <div class="flex text-sm text-gray-500">
                    {{ 'XU.BALANCE_COIN' | translate }}
                    <span class="font-medium mx-1">{{ ' ' + item.transactions_history.new_balance + ' ' }}</span>
                    {{ 'XU.XU' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="monthGroups.length === 0 && !loadingData">
    <div class="mt-2 mx-2 text-lg font-semibold">{{ 'XU.DO_NOT_HAVE_TRANSACTION' | translate }}</div>
  </ng-container>
  <div *ngIf="loadingData || loadingMoreData" class="w-full mt-4 mx-2">
    <loading-skeleton [isHistoryCoin]="true"></loading-skeleton>
  </div>
  