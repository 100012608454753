<p-skeleton
  *ngIf="isLoading && !(mediaList.length === 1 && mediaList[0].type === MEDIA_TYPE.video)"
  styleClass="bg-no-repeat bg-center w-full h-[584px] object-center"></p-skeleton>
<img
  *ngIf="isLoading && (mediaList.length === 1 && mediaList[0].type === MEDIA_TYPE.video)"
  defaultImage
  class="w-full h-full object-center bg-palette-gray-900 aspect-square"
  [src]="videoThumbnail(mediaList[0].original)"/>
<div
  #media
  *ngIf="!isLoading"
  class="gap-0.5 p-0.5 grid-container w-full"
  [ngClass]="{
    'grid auto-rows-auto box-border': mediaList.length > 1,
    'aspect-square': mediaList.length > 1 && !(layoutType === LAYOUT_TYPE.vertical && mediaList.length >= 5),
    'layout-central': layoutType === LAYOUT_TYPE.central && mediaList.length === 4,
    'grid-cols-6 grid-rows-5': layoutType === LAYOUT_TYPE.vertical && mediaList.length > 2,
    'layout-v2': layoutType === LAYOUT_TYPE.vertical && mediaList.length === 2,
    'layout-v3': layoutType === LAYOUT_TYPE.vertical && mediaList.length === 3,
    'layout-v4': layoutType === LAYOUT_TYPE.vertical && mediaList.length === 4,
    'layout-v5': layoutType === LAYOUT_TYPE.vertical && mediaList.length >= 5,
    'grid-cols-5 grid-rows-6': layoutType === LAYOUT_TYPE.horizontal && mediaList.length > 2,
    'layout-h2': layoutType === LAYOUT_TYPE.horizontal && mediaList.length === 2,
    'layout-h3': layoutType === LAYOUT_TYPE.horizontal && mediaList.length === 3,
    'layout-h4': layoutType === LAYOUT_TYPE.horizontal && mediaList.length === 4,
    'layout-h5': layoutType === LAYOUT_TYPE.horizontal && mediaList.length >= 5
  }">
  <ng-container *ngFor="let item of mediaList; let i = index">
    <div *ngIf="i < 5" [class]="'relative cursor-pointer bg-gray-100 item' + (i + 1)">
      <div #container
        class="w-full h-full"
        [ngClass]="{
          'aspect-auto': mediaList.length === 1 && isLandscapeImg,
          'aspect-square':
            mediaList.length >= 3 || (mediaList.length === 1 && !isLandscapeImg && media.offsetWidth < 600),
          'aspect-[4/3]': mediaList.length === 1 && !isLandscapeImg && media.offsetWidth > 600
        }"
        (click)="item.type === MEDIA_TYPE.image ? onClickImage(i) : clickVideo(i)">
        <div *ngIf="isScale" class="flex items-center justify-center w-full bg-palette-gray-600" [ngStyle]="{'height': container.offsetHeight + 'px'}">{{'POST.NOW_PLAYING' | translate}}</div>
        <p-skeleton
          *ngIf="item.is_loading"
          styleClass="bg-no-repeat bg-center w-full h-full object-center"></p-skeleton>
        <img
          defaultImage
          loading="lazy"
          #postImg
          (load)="handleImageLoad(i)"
          (error)="handleImageError(i)"
          *ngIf="item.type === MEDIA_TYPE.image && i < 5"
          [src]="handleGenerateUrl(item, FILE_TYPE_URL.thumbnail, this.FILE_EXTENSION.image)"
          class="bg-no-repeat bg-center w-full h-full object-center seo-image"
          [ngClass]="{
            'object-contain': mediaList.length === 1 && !isLandscapeImg,
            'object-cover': mediaList.length > 1,
            'invisible': item.is_loading
          }" />
        <ng-container *ngIf="item.type === MEDIA_TYPE.video" >
          <ng-template
            custom-video-directive
            *ngIf="!isSharedPost"
            [isPreview]="isPreview"
            [isAutoPlay]="isAutoPlay"
            (isClickedPlay)="checkPlayClicked($event)"
            [item]="item"
            [isCover]="!(mediaList.length === 1 && !isLandscapeImg)"
            [isShowScaleIcon]="mediaList.length === 1"
            (collectVideo)="handleCollectVideo($event)"
            (scaleVideo)="handleScaleVideo($event)"></ng-template>
          <custom-video
            *ngIf="isSharedPost"
            [isPreview]="isPreview"
            [isShareView]="true"
            (isClickedPlay)="checkPlayClicked($event)"
            [item]="item"
            [isAutoPlay]="isAutoPlay"
            [isCover]="!(mediaList.length === 1 && !isLandscapeImg)"></custom-video>
        </ng-container>
      </div>
      <span
        *ngIf="mediaList.length > 5 && i === 4"
        (click)="onClickImage(i)"
        class="w-full h-full absolute top-0 left-0 text-center flex justify-center items-center">
        <b
          class="absolute {{
            isPreview
              ? isSmallPreview
                ? 'text-[10px] leading-[10px] p-1'
                : 'text-2xl p-4'
              : 'text-[32px] leading-9 p-3'
          }} text-white rounded-full accent-gray-400"
          >+{{ mediaList.length - 5 }}</b
        >
      </span>
    </div>
  </ng-container>
</div>
