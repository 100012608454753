import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { BookmarksService } from '@app/core/services/bookmarks.service';
import { CommonService } from '@app/core/services/common.service';
import { FriendService } from '@app/core/services/friend.service';
import { MediaService } from '@app/core/services/media.service';
import { PollService } from '@app/core/services/poll.service';
import { PostsService } from '@app/core/services/posts.service';
import { ReportService } from '@app/core/services/report.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { ADS_ACTION, ADS_CATEGORY } from '@app/lib/api/client/api.client.constant';
import { PollObject } from '@app/lib/api/poll/api.poll.model';
import { PostRequest } from '@app/lib/api/post/api.post.model';
import { EditPostActions, EditPostApiActions } from '@app/modules/main/states/edit-post/edit-post.actions';
import { isEditPostFail, isEditPostSuccess, postUpdated } from '@app/modules/main/states/edit-post/edit-post.selectors';
import { FriendActions } from '@app/modules/main/states/friend/friend.actions';
import { selectFollowStatus, selectUnFollowLoading } from '@app/modules/main/states/friend/friend.selectors';
import { NewsFeedPostsActions } from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.actions';
import {
  selectNewsFeedBookmarkBloomFilter,
  selectNewsFeedFollowFanpageBloomFilter,
  selectNewsFeedFollowUserBloomFilter
} from '@app/modules/main/states/newsfeed-posts/newsfeed-posts.selectors';
import { getReportCategories } from '@app/modules/main/states/report/report.actions';
import { selectReportCategories } from '@app/modules/main/states/report/report.selectors';
import { UserPostsActions, UserPostsApiActions } from '@app/modules/main/states/user-posts/user-posts.actions';
import { selectLoading } from '@app/modules/main/states/user-posts/user-posts.selectors';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import {
  AVATAR_SIZE,
  AVATAR_TYPE,
  OBJECT_TYPE,
  POST_TYPE,
  PRIVACY_OPTIONS,
  TOAST_MESSAGE_SEVERITY_LEVELS,
  TYPE_TAB_VIEW_ITEMS,
  VERIFICATION_STATUS
} from '@app/shared/constant';
import { FriendList } from '@app/shared/models/friend';
import { GroupDetailModel } from '@app/shared/models/group.model';
import {
  DROPDOWN_POST,
  FriendTag,
  HeaderPost,
  MediaInfo,
  POLL_STATUS,
  Post,
  PostAction,
  SHARE_TYPE,
  ShareToFeedInfo,
  actionsPost
} from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { GetDatePipe } from '@app/shared/pipes/getdatetime.pipe';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { isUndefined, omitBy } from 'lodash-es';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { combineLatest, filter, take } from 'rxjs';
import { PostDialogComponent } from '../../create-post-dialog/post-dialog/post-dialog.component';
@Component({
  selector: 'post-header',
  templateUrl: './post-header.component.html',
  styleUrls: ['./post-header.component.scss']
})
export class PostHeaderComponent implements OnInit, AfterViewInit {
  @Input() userInfo: UserInfo;
  @ViewChild(PostDialogComponent) postDialog: any;
  @Input() data: HeaderPost;
  @Input() isPinnedPost: boolean;
  @Input() isPostInProfile: boolean;
  @Input() isSharedPost: boolean;
  @Input() isLoggedUser: boolean;
  @Input() dataInit: any;
  @Input() isShowConfig = true;
  @Input() isGroupStyle = false;
  @Input() isPostGroup = false;
  @Input() isFanpagePost = false;
  @Input() isShowActualPoster = false;
  @Input() groupId: string;
  @Input() fanpageInfo: any;
  @Input() isShowHideBtn: boolean;
  @Input() groupPrivacy: string;
  @Input() isUpdatedPost = false;
  @Input() isPostSearching = false;
  @Input() isShowDetailPost: boolean;
  @Input() isPostDetail: boolean;
  @Input() isNewsfeed: boolean;
  @Input() isWatchScreen: boolean;
  @Input() isPostView = false;
  @Output() postData: EventEmitter<string> = new EventEmitter();
  @Output() deletePost = new EventEmitter();
  @Output() postEditInNewsFeed = new EventEmitter();
  @Output() pinPostEmit = new EventEmitter();
  @Output() unPinEmit = new EventEmitter();
  @Output() hidePost = new EventEmitter();
  @Output() archivePost = new EventEmitter();
  @Output() download_image_or_video = new EventEmitter();
  @Output() unFollowEmit = new EventEmitter();

  // Edit privacy
  isEditPost = false;
  isEditPollPost = false;
  isEditPostSuccess$ = this.store.select(isEditPostSuccess);
  isEditPostFail$ = this.store.select(isEditPostFail);
  postUpdated$ = this.store.select(postUpdated);
  reportCategories$ = this.store.pipe(select(selectReportCategories));
  privacyPostSelected: any;

  privacy: string;
  hostUrl = environment.baseURL;
  userLogged$ = this.store.select(selectUserInfo);
  selectFollowStatus$ = this.store.select(selectFollowStatus);
  loadingUnFollow$ = this.store.select(selectUnFollowLoading);
  loggedUserId: string;
  postPrivacy: any;
  shareType = SHARE_TYPE;
  shareToGroupInfo: GroupDetailModel;
  shareToFeedInfo: ShareToFeedInfo;
  isShowReport = false;
  isReportPost = false;
  isEditPrivacy = false;
  isPinned = false;
  isFollowUser = false;
  isFollowFanpage = false;
  onHandleFollowAction = false;
  showBookMarkCategory = false;
  translatePostResult: any;
  translateCommonResult: any;
  POST_TYPE = POST_TYPE;
  friendList: FriendList[];
  isBookmark = false;
  translateBookMarkResult: any;
  postIds: string[] = [];
  isModerate = false;
  albumFilesCount = '';
  // TODO :
  @Input() configType: number = DROPDOWN_POST.default;
  loading = this.store.select(selectLoading);
  isOpenPopupLogin = false;
  postOptions: PostAction[];
  overlayVisible = false;
  loadingEditPost = false;
  avatarType = AVATAR_TYPE;
  avatarSize = AVATAR_SIZE;
  dialogConfirmVisible = false;
  baseUrl: string = environment.baseURL;
  dialogTagFriendsVisible = false;
  isLoadingReport = false;
  isLoggedIn = false;
  isShowDialogRemoveTag = false;
  isLoadingConfirm = false;
  adsAction = ADS_ACTION;
  verificationStatus = VERIFICATION_STATUS;
  currentUserId: string;
  pageNum = 0;
  pageSize = 10;
  pollData: PollObject;

  constructor(
    public router: Router,
    private store: Store,
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslationService,
    private getDatePipe: GetDatePipe,
    private bookmarksService: BookmarksService,
    private postService: PostsService,
    private cdRef: ChangeDetectorRef,
    private friendService: FriendService,
    private toastMessageService: ToastMessageService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private mediaService: MediaService,
    private commonService: CommonService,
    private pollService: PollService
  ) {}

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  getPostData(data: any) {
    if (data) {
      const newsPost: PostRequest = {
        ...this.dataInit,
        title: '',
        content: data.content,
        post_privacy: data.post_privacy,
        post_type: data.post_type,
        group_id: data.group_id,
        page_id: data.page_id,
        location_id: data.location_id,
        friend_tags: data.friend_tags || [],
        media_urls: data.media_urls?.media?.length > 0 ? data.media_urls : null,
        check_in: data.check_in,
        status: 0,
        post_feeling: data.post_feeling,
        message_ranges: data.message_ranges,
        theme_id: data.theme_id,
        livestream_info: data.livestream_info,
        preview_data: data.preview_data
      };
      if (this.isPostInProfile) {
        switch (this.data.post_pattern) {
          case POST_TYPE.normal:
          case POST_TYPE.album:
          case POST_TYPE.link:
            this.store.dispatch(
              UserPostsActions.onUserUpdateOwnPost({
                post: newsPost,
                id: this.data.id,
                isPinnedPost: this.isPinnedPost
              })
            );
            break;
          case POST_TYPE.share:
            this.store.dispatch(
              UserPostsActions.onUserUpdateSharePost({
                post: newsPost,
                id: this.data.id,
                isPinnedPost: this.isPinnedPost
              })
            );
            break;
          default:
            break;
        }
      } else {
        switch (this.data.post_pattern) {
          case POST_TYPE.normal:
          case POST_TYPE.link:
          case POST_TYPE.album:
            this.postService.updatePost(newsPost, this.data.id).subscribe({
              next: data => {
                this.postEditInNewsFeed.emit(data);
                this.toastMessageService.addToastMessage(
                  TOAST_MESSAGE_SEVERITY_LEVELS.success,
                  'ERROR.POST.POST_UPDATE_SUCCESSFULLY'
                );
              }
            });
            break;
          case POST_TYPE.share:
            this.postService.updateSharedPost(newsPost, this.data.id).subscribe({
              next: data => {
                this.postEditInNewsFeed.emit(data);
                this.toastMessageService.addToastMessage(
                  TOAST_MESSAGE_SEVERITY_LEVELS.success,
                  'ERROR.POST.POST_UPDATE_SUCCESSFULLY'
                );
              }
            });
            break;
          default:
            break;
        }
      }
    }
  }
  ngOnInit(): void {
    this.currentUserId = JSON.parse(localStorage.getItem('user_profile') || '{}')?.id;
    this.translatePostResult = this.translateService.getTranslation('POST');
    this.translateCommonResult = this.translateService.getTranslation('COMMON');
    this.translateBookMarkResult = this.translateService.getTranslation('BOOKMARK');
    this.isLoggedIn = JSON.parse(localStorage.getItem('auth_status') ?? '[]')?.isLoggedIn || false;
    this.isModerate = this.route.snapshot.queryParams['admin'] === 'true';
    combineLatest([
      this.store.select(selectNewsFeedBookmarkBloomFilter),
      this.store.select(selectNewsFeedFollowUserBloomFilter),
      this.store.select(selectNewsFeedFollowFanpageBloomFilter)
    ]).subscribe(([bookmarkRes, followUserRes, followFanapgeRes]) => {
      this.isBookmark = bookmarkRes !== '' ? this.handleBloomFilter(bookmarkRes, this.data.id) : false;
      this.isFollowUser =
        followUserRes !== '' && this.data.user_id ? this.handleBloomFilter(followUserRes, this.data.user_id) : false;
      this.isFollowFanpage =
        followFanapgeRes !== '' && this.data.page_id
          ? this.handleBloomFilter(followFanapgeRes, this.data.page_id)
          : false;
    });
    this.postService.followSubject.pipe(take(1)).subscribe(followData => {
      if (followData.page_id) {
        if (followData.page_id.follow?.includes(this.data.page_id)) this.isFollowFanpage = this.isFollowUser = true;
        if (followData.page_id.unfollow?.includes(this.data.page_id)) this.isFollowFanpage = this.isFollowUser = false;
        return;
      }
      if (followData.user_id) {
        if (followData.user_id.follow?.includes(this.data.user_id)) this.isFollowUser = true;
        if (followData.user_id.unfollow?.includes(this.data.user_id)) this.isFollowUser = false;
      }
    });
    this.initPostOptions();
  }

  handleBloomFilter(bloomString: string, userId: string) {
    if (bloomString === '') return false;
    const { BloomFilter } = require('soctrip-algorithm/dist/api.js');
    const bloomFilter = BloomFilter.load(bloomString);
    return bloomFilter.has(userId);
  }

  initPostOptions() {
    if (this.userInfo) {
      const isFriendTagged = this.data.friend_tags?.some(tag => tag.user_id === this.currentUserId) || false;
      const postType = this.data?.post_type;
      const listPostType = this.postService.handleCheckTypeActionPost(
        this.configType,
        this.userInfo.id,
        this.loggedUserId,
        this.isBookmark,
        this.data.ads_id ? true : false,
        this.isPinned,
        this.isFollowUser,
        this.data.is_archive ? true : false,
        this.isShowDetailPost,
        isFriendTagged,
        postType
      );
      if (listPostType && listPostType?.length > 0) {
        this.postOptions = this.isShowHideBtn ? listPostType : listPostType.filter(s => s.action != actionsPost.hide);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      if (this.data?.post_info?.user_object) {
        this.userInfo = this.data.post_info.user_object;
      } else {
        this.userInfo = this.data.user_object;
      }
      this.postPrivacy = PRIVACY_OPTIONS.find(el =>
        el.privacy === this.data.post_privacy ? this.data.post_privacy : this.data?.post_info?.post_privacy
      );

      if (
        this.data.post_pattern === POST_TYPE.album &&
        this.data.library_object &&
        this.data.media_urls &&
        !!this.data.media_urls.media
      ) {
        this.albumFilesCount = this.getFileCount(this.data.media_urls);
      }

      this.privacy = this.data.post_privacy;
      this.store.select(selectUserInfo).subscribe(res => {
        if (res) this.loggedUserId = res.id;
      });

      this.privacyPostSelected = PRIVACY_OPTIONS.find(item => item.privacy === this.data.post_privacy);

      if (this.data.shareType) {
        if (this.data.shareType === SHARE_TYPE.group && this.data.shareToGroupInfo) {
          this.shareToGroupInfo = this.data.shareToGroupInfo;
        } else if (this.data.shareToFeedInfo) {
          this.shareToFeedInfo = this.data.shareToFeedInfo;
        }
      }
    }
    if (changes['configType'] && changes['configType'].currentValue) {
      this.isBookmark = false;
      this.initPostOptions();
    }
    if ((changes['isUpdatedPost'] && changes['isUpdatedPost'].currentValue) || changes['isPinnedPost']) {
      if (this.loadingEditPost) {
        this.loadingEditPost = false;
      }
    }
  }

  toggle() {
    if (!this.isOpenPopupLogin) {
      this.overlayVisible = !this.overlayVisible;
    }
  }
  handlerAction(action: any, idItem: any, data: any) {
    switch (action) {
      case actionsPost.edit:
        if (data?.poll_id) {
          this.pollService.getPollData(data.poll_id).subscribe(res => {
            if (res.success) {
              if (res.data.status !== POLL_STATUS.END) {
                this.pollData = res.data;
                this.isEditPollPost = true;
              } else {
                this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'POLL.ENDED_POLL');
              }
            }
          });
          this.overlayVisible = false;
          return;
        }
        this.isEditPost = true;
        this.friendService.getFriend(0).subscribe(res => (this.friendList = res.data?.content));
        this.postDialog.selectedFiles =
          this.dataInit?.media_urls?.media?.map((el: { original: any; thumbnail: any; type: any }) => {
            let itemImg = { ...el };
            itemImg.original = el.original;
            itemImg.thumbnail = el.thumbnail;
            itemImg.type = el.type;
            return itemImg;
          }) || [];
        this.postDialog.selectionFriendTag =
          this.dataInit?.friend_tags?.map((el: any) => {
            let item = { ...el.user_object };
            item.user_id = el.user_id;
            return item;
          }) || [];
        this.postDialog.showUploadSection = this.dataInit?.media_urls?.media.length > 0 ? true : false;
        this.postDialog.selectedStatus = this.dataInit?.post_feeling;
        this.postDialog.privacy = PRIVACY_OPTIONS.find(x => x.privacy === this.privacy);
        this.postDialog.checkInObject = this.dataInit.check_in;
        this.postDialog.previewData = this.dataInit?.preview_data;
        this.postDialog.openDialog();
        this.postDialog.content = this.dataInit.content;
        this.postDialog.isGroupPost = this.dataInit!.group_id;
        this.postDialog.isFanpagePost = this.dataInit!.page_id;
        this.postDialog.selectedTheme = this.dataInit.theme_object;
        this.postDialog.livestream_info = this.dataInit.livestream_info;
        this.postDialog.shareType = this.data.shareType;
        break;
      case actionsPost.delete:
        this.deletePost.emit(data);
        break;
      case actionsPost.report:
        this.reportCategories$.pipe(filter(res => !res)).subscribe(() => {
          this.store.dispatch(getReportCategories());
        });
        this.isShowReport = true;
        this.isReportPost = true;
        break;
      case actionsPost.edit_privacy:
        this.isEditPrivacy = true;
        break;
      case actionsPost.bookmark:
        if (this.isBookmark) {
          this.bookmarksService.deleteBookmark(this.data.id).subscribe(res => {
            if (res) {
              this.isBookmark = false;
              this.initPostOptions();
              this.messageService.add({
                severity: 'success',
                summary: this.translateCommonResult.SUCCESS,
                detail: this.translateService.getTranslation('BOOKMARK.UNSAVE_BOOKMARK_SUCCESSFULLY')
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: this.translateCommonResult.SUCCESS,
                detail: this.translateService.getTranslation('BOOKMARK.UNSAVE_BOOKMARK_FAIL')
              });
            }
          });
        } else {
          this.showBookMarkCategory = true;
          this.onBookmarkAdded();
        }
        this.isBookmark = false;
        this.initPostOptions();
        break;
      case actionsPost.pin_post:
        this.loadingEditPost = true;
        this.pinPostEmit.emit();
        break;
      case actionsPost.unpin:
        this.loadingEditPost = true;
        this.unPinEmit.emit();
        break;
      case actionsPost.unpin:
        this.loadingEditPost = true;
        this.unPinEmit.emit();
        break;
      case actionsPost.unfollow:
        this.onUnfollow(data);
        break;
      case actionsPost.follow:
        this.onFollow(data);
        break;
      case actionsPost.hide:
        this.dialogConfirmVisible = true;
        break;
      case actionsPost.archive:
        this.archivePost.emit(data);
        break;
      case actionsPost.download_image_or_video:
        this.download_image_or_video.emit();
        break;
      case actionsPost.remove_tag:
        this.isShowDialogRemoveTag = true;
        break;
      default:
        break;
    }
    this.overlayVisible = false;
  }
  accept(idItem: string) {
    this.hidePost.emit({ id: idItem, isReport: false });
    this.overlayVisible = false;
    this.messageService.add({
      severity: 'success',
      summary: this.translateCommonResult.SUCCESS,
      detail: this.translateService.getTranslation('POST.SUCCESSFUL_MESSAGE.HIDE_POST_SUCCESSFULLY')
    });
    this.dialogConfirmVisible = false;
  }

  onFollow(data: any) {
    const followData = this.postService.followSubject.value;
    if (data.page_id) {
      this.trackingAdsEvent(ADS_ACTION.follow);
      this.handleOnFollow('page', data.page_id);
      followData.page_id.follow.push(data.page_id);
      this.postService.followSubject.next({ ...followData });
    } else {
      this.handleOnFollow('user', data.user_id);
      followData.user_id.follow.push(data.user_id);
      this.postService.followSubject.next({ ...followData });
    }
  }

  handleRemoveTag(): void {
    this.isLoadingConfirm = true;
    this.postService.removeTaggedPosts([this.data.id], false).subscribe(
      res => {
        if (res) {
          const friendTagsRemoved = this.data.friend_tags?.filter(tag => tag.user_id !== this.currentUserId);
          this.data = { ...this.data, friend_tags: friendTagsRemoved };
          this.postEditInNewsFeed.emit(this.data);
          this.initPostOptions();
          this.isShowDialogRemoveTag = false;
          this.hidePost.emit({ id: this.data.id, isReport: false });
          this.messageService.add({
            severity: 'success',
            summary: this.translateCommonResult.SUCCESS,
            detail: this.translateService.getTranslation('POST.SUCCESSFUL_MESSAGE.REMOVE_TAG_SUCCESSFULLY')
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateCommonResult.ERROR,
            detail: this.translateService.getTranslation('POST.ERROR_MESSAGE.REMOVE_TAG_FAILED')
          });
        }
        this.isLoadingConfirm = false;
      },
      error => {
        this.isLoadingConfirm = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateCommonResult.ERROR,
          detail: this.translateService.getTranslation('POST.ERROR_MESSAGE.REMOVE_TAG_FAILED')
        });
      }
    );
  }

  handleOnFollow(typeService: string, id: string) {
    this.onHandleFollowAction = true;
    this.store.dispatch(
      FriendActions.onFollowUserState({
        typeService: typeService,
        typeId: id
      })
    );
    this.selectFollowStatus$.subscribe(item => {
      if (item) {
        typeService === 'page' ? (this.isFollowFanpage = this.isFollowUser = true) : (this.isFollowUser = true);
        this.initPostOptions();
        this.onHandleFollowAction = false;
      }
    });
  }

  onUnfollow(data: any) {
    const followData = this.postService.followSubject.value;
    if (data.page_id) {
      this.handleUnFollow('page', data.page_id);
      followData.page_id.unfollow.push(data.user_id);
      this.postService.followSubject.next({ ...followData });
    } else {
      this.handleUnFollow('user', data.user_id);
      followData.user_id.unfollow.push(data.user_id);
      this.postService.followSubject.next({ ...followData });
    }
  }

  handleUnFollow(typeService: string, id: string) {
    this.onHandleFollowAction = true;
    this.store.dispatch(
      FriendActions.onUnfollowUserState({
        typeService: typeService,
        typeId: id
      })
    );
    this.selectFollowStatus$.subscribe(item => {
      if (item) {
        typeService === 'page' ? (this.isFollowFanpage = this.isFollowUser = false) : (this.isFollowUser = false);
        this.initPostOptions();
        this.onHandleFollowAction = false;
      }
    });
  }

  onBookmarkAdded() {
    this.store.dispatch(
      NewsFeedPostsActions.onGetBookmarkCollection({ pageNum: this.pageNum, pageSize: this.pageSize })
    );
    this.isBookmark = true;
    this.initPostOptions();
  }

  onBookMarked(isBookmarked: boolean) {
    this.isBookmark = isBookmarked;
  }

  handleGenerateURL(isSharedInfo: boolean): string {
    if (!isSharedInfo) {
      // Route with group ID only if this is not a post in group.
      if (this.data.group_id && !this.isPostGroup) {
        return '/group/' + this.data.group_id;
      } else if (this.data.page_object) {
        return this.data.ads_id
          ? `/fanpage/${this.commonService.convertToSnakeCaseString(this.data.page_object.page_name)}/${
              this.data.page_id
            }?adsId=${this.data.ads_id}`
          : `/fanpage/${this.commonService.convertToSnakeCaseString(this.data.page_object.page_name)}/${
              this.data.page_id
            }`;
      } else {
        return '/personal-profile/' + (this.userInfo?.username || this.userInfo?.id);
      }
    } else {
      if (this.data.shareType === this.shareType.group) {
        return '/group/' + this.shareToGroupInfo.group_id;
      } else {
        return '/personal-profile/' + this.shareToFeedInfo.id;
      }
    }
  }

  handleCloseModal(event: any) {
    this.isShowReport = event;
  }

  handleCloseEditPrivacy(event: any) {
    this.isEditPrivacy = event.isClose;
    if (this.isPostInProfile) {
      this.store.dispatch(
        UserPostsApiActions.onEditUserPostSuccess({
          id: this.data.id,
          key: event.key,
          value: event.value
        })
      );
    }
    if (event.postUpdated && Object.keys(event.postUpdated).length) {
      this.postEditInNewsFeed.emit(event.postUpdated);
    }
    this.store.dispatch(EditPostApiActions.onResetEditPrivacy());
  }

  handleGetObjectParentType(): string {
    if (this.isPostGroup || this.data.group_object) return OBJECT_TYPE.group;
    if (this.isFanpagePost || this.data.page_object) return OBJECT_TYPE.fanpage;
    return 'USER';
  }

  handleGetObjectParentId() {
    if (this.isPostGroup || this.data?.group_object) return this.groupId || this.data?.group_object?.group_id;
    if (this.isFanpagePost || this.data.page_object) return this.data.page_id || this.data?.page_object?.page_id;
    return this.data?.user_object?.id;
  }

  handlePostReport(event: any) {
    let param = {
      object_id: this.data.id,
      object_type: OBJECT_TYPE.post,
      category_type: event.category_type,
      object_parent_id: this.handleGetObjectParentId() || '',
      object_parent_type: this.handleGetObjectParentType() || '',
      report_reason: event.report_reason,
      key_search: this.data.user_object.username,
      owner_id: this.data.user_object.id,
      city_code: this.data?.user_object?.city_code
    };

    if (this.data.post_type === OBJECT_TYPE.ads) {
      param = {
        ...param,
        object_id: this.data.ads_id || '',
        object_type: OBJECT_TYPE.ads,
        key_search: this.data?.page_object?.user_name || '',
        owner_id: this.data.page_id || ''
      };
      const objectParent = {
        object_parent_id: this.data.page_id || '',
        object_parent_type: OBJECT_TYPE.fanpage,
        city_code: this.data?.page_object?.city_code
      };
      param = { ...param, ...objectParent };
    }
    if (this.data.post_type === OBJECT_TYPE.livestream) {
      param = {
        ...param,
        object_type: OBJECT_TYPE.livestream
      };
    }
    this.reportService.createReportPost(param).subscribe(res => {
      this.isLoadingReport = false;
      if (res.success) {
        this.isShowReport = false;
        this.hidePost.emit({ id: this.data.id, isReport: true });
      } else {
        this.toastMessageService.addToastMessage(
          TOAST_MESSAGE_SEVERITY_LEVELS.error,
          (this.translatePostResult = 'ERROR.POST.REPORT_POST_FAILED')
        );
      }
    });
  }

  translateStatus(status: string): string {
    const key = status.toUpperCase();
    return this.translateService.getTranslation(`POST.STATUS.${key}`) || '';
  }

  goInfoDetail(id: string) {
    if (this.isModerate) return;
    if (id) this.router.navigateByUrl('/personal-profile/' + id);
  }

  setTooltipFriendTag(arrTagFriend: FriendTag[]) {
    let html = '';
    arrTagFriend.forEach((user, i) => {
      if (i !== 0) {
        html = html + `<div class="text-palette-base-white text-sm">${user.user_object.full_name}</div>`;
      }
    });
    return html;
  }

  navigateToFanPage(checkInData: any) {
    if (this.isModerate) return;
    this.trackingAdsEvent(ADS_ACTION.checkIn);
    if (!checkInData.object_id) {
      this.router.navigateByUrl(
        `/fanpage/${this.commonService.convertToSnakeCaseString(this.data.page_object?.page_name || '')}/${
          this.data.page_id
        }`
      );
      return;
    }
    this.router.navigateByUrl(
      `/fanpage/${this.commonService.convertToSnakeCaseString(checkInData.object_data_check_in.page_name)}/${
        checkInData.object_id
      }`
    );
  }

  trackingAdsEvent(action: string, event?: Event): void {
    if (this.isModerate) {
      event?.preventDefault();
      return;
    }
    if (this.dataInit && this.dataInit.ads_id) {
      this.analyticsService.pushToBuffer(
        this.dataInit.ads_id,
        this.isPostSearching ? ADS_CATEGORY.adsSearch : ADS_CATEGORY.adsFeed,
        action
      );
    }
  }

  navigateToProfile() {
    if (this.isModerate) return;
    this.router.navigateByUrl('/personal-profile/' + this.userInfo.id);
  }

  navigateToLibrary(post: Post) {
    if (this.isModerate) return;
    if (post.library_object) {
      const groupId = post.group_object && post.group_object.group_id ? post.group_object.group_id : '';
      const fanpageId = post.page_object && post.page_object.page_id ? post.page_object.page_id : '';
      const routerLink = groupId
        ? `/group/${groupId}`
        : fanpageId
        ? `/fanpage/${this.commonService.convertToSnakeCaseString(post.page_object?.page_name || '')}/${fanpageId}`
        : `/personal-profile/${post.library_object.owner_id}`;
      const routerPrams = `?Tab=${TYPE_TAB_VIEW_ITEMS.LIBRARY.TITLE}&Library=${post.library_object.id}`;
      this.router.navigateByUrl(`${routerLink}${routerPrams}`);
    }
  }

  handleEditPrivacy(event: string) {
    const postEdit: any = {
      ...this.data,
      post_type: this.data.post_type,
      media_urls: this.data.media_urls || {},
      friend_tags:
        this.data.friend_tags?.map(el => {
          return {
            user_id: el.user_id,
            status: el?.user_object?.allow_tag_moderation ? VERIFICATION_STATUS.pending : el.status
          };
        }) || [],
      post_privacy: event,
      message_ranges: this.data.message_ranges || [],
      livestream_info: this.data.livestream_info || null
    };
    switch (this.data.post_pattern) {
      case POST_TYPE.normal:
        this.store.dispatch(
          EditPostActions.onEditPrivacyOwnPost({
            post: postEdit,
            postId: this.data.id
          })
        );
        break;
      case POST_TYPE.album:
        this.store.dispatch(
          EditPostActions.onEditPrivacyOwnPost({
            post: postEdit,
            postId: this.data.id
          })
        );
        break;
      case POST_TYPE.share:
        this.store.dispatch(
          EditPostActions.onEditPrivacySharedPost({
            post: postEdit,
            postId: this.data.id
          })
        );
        break;
      default:
        break;
    }
  }

  removeUndefinedFields(obj: Object) {
    return omitBy(obj, isUndefined);
  }

  formattedContent() {
    let content = this.dataInit.content || '';
    if (this.dataInit.message_ranges) {
      for (let i = this.dataInit.message_ranges.length - 1; i >= 0; i--) {
        const element = this.dataInit.message_ranges[i];
        const offset = element.offset;
        const length = element.length;
        const firstPart = content.slice(0, offset);
        const secondPart = content.slice(offset + length);

        let contentReplace = `<a href="/personal-profile/${
          element.entity_id
        }" class="text-sm font-semibold text-branding-primary-700 hover:cursor-pointer hover:underline">${content.slice(
          offset,
          offset + length
        )}</a>`;
        if (this.isEditPost) {
          contentReplace = `<span class="text-branding-primary-700">${content.slice(offset, offset + length)}</span>`;
        }

        content = firstPart + contentReplace + secondPart;
      }
    }
    return content.replace(/\n/g, '<br>');
  }

  getFromNow() {
    const commentUpdatedAt = moment(this.data.posted_date);
    const now = moment();
    const timeDifferenceInSeconds = now.diff(commentUpdatedAt, 'seconds');
    if (timeDifferenceInSeconds <= 60) {
      return this.translateService.getTranslation('COMMENT.JUST_NOW');
    } else {
      return this.getDatePipe.transform(commentUpdatedAt, timeDifferenceInSeconds);
    }
  }

  getReportTarget(): string {
    return this.translateService.getTranslation('COMMON.POST').toLowerCase();
  }

  clickArchivePost(postId: string, isArchive = true) {
    this.postIds.push(postId);
    this.postService.createArchivePost(this.postIds, isArchive).subscribe();
  }

  getFileCount(mediaInfo: MediaInfo): string {
    let result = '';
    const fileCount = this.mediaService.countFilesByTypes(mediaInfo.media);
    if (!!fileCount.image) {
      result = `${fileCount.image} ${this.translateService.getTranslation(
        fileCount.image === 1 ? 'MEDIA.PHOTO' : 'MEDIA.PHOTOS'
      )}`;
    }
    if (!!fileCount.image && !!fileCount.video) {
      result = `${result} ${this.translateService.getTranslation('COMMON.AND')}`;
    }
    if (!!fileCount.video) {
      result = `${result} ${fileCount.video} ${this.translateService.getTranslation(
        fileCount.video === 1 ? 'MEDIA.VIDEO' : 'MEDIA.VIDEOS'
      )}`;
    }
    return result.toLowerCase();
  }

  openPopup(event: any) {
    this.isOpenPopupLogin = event;
  }

  getImgUrl(imgUrl: string | undefined): string {
    if (imgUrl?.indexOf('/storage/files/web/') !== -1) {
      return `${this.baseUrl}${imgUrl}`;
    }
    return this.commonService.getImageUrl(imgUrl);
  }

  navigateToProfileFriends(friendTag: FriendTag) {
    if (friendTag.status === VERIFICATION_STATUS.pending && friendTag.user_id !== this.currentUserId) return;
    if (this.isModerate) return;
    this.router.navigateByUrl('/personal-profile/' + friendTag.user_id);
  }

  handleBlockNavigationWithAdminView(event?: Event) {
    if (this.isModerate) {
      event?.preventDefault();
      return;
    }
  }
}
