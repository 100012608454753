import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MediaService } from '@app/core/services/media.service';
import { PostsService } from '@app/core/services/posts.service';
import { ToastMessageService } from '@app/core/services/toast-message.service';
import { TranslationService } from '@app/core/services/translation.service';
import { UtilityService } from '@app/core/services/utility.service';
import { PreviewLink } from '@app/lib/api/post/api.post.model';
import { selectUserInfo } from '@app/modules/main/states/users/users.selectors';
import {
  AllowedTypes,
  FILE_EXTENSION,
  MAXIMUM_UPLOAD_COUNT,
  MEDIA_FILE_FORMAT_LIMITS,
  MEDIA_TYPE,
  TOAST_MESSAGE_SEVERITY_LEVELS
} from '@app/shared/constant';
import { FILE_TYPE_URL, MediaComment, MessageRanges, checkUrl } from '@app/shared/models/post';
import { UserInfo } from '@app/shared/models/user';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Listbox } from 'primeng/listbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { filter } from 'rxjs';

@Component({
  selector: 'comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent implements AfterViewInit {
  @Input() loginUser: UserInfo;
  @Input() isReplyForm = false;
  @Input() isEditComment = false;
  @Input() isInsertImage = true;
  @Input() isStory = false;
  @Input() userStory = false;
  @Input() isPostView = false;
  @Input() contentComment = '';
  @Input() mediaComment: MediaComment[] = [];
  @Input() messageRanges: MessageRanges[] = [];
  @Input() bgComment: string = 'bg-palette-base-white';
  @Input() color: string;
  @Input() replyTarget: { id: string; full_name: string };
  @Input() previewData: PreviewLink | null = null;
  @Output() isEditCommentChange = new EventEmitter();
  @Output() valueComment = new EventEmitter();
  @Output() focusEvent = new EventEmitter<boolean>();
  isFocusInput = false;
  tagCount = 0;
  commentText = '';
  selectedFiles: any = [];
  medias: MediaComment[] = [];
  showProgress = false;
  translate: any;
  caretPosition = 0;
  FILE_TYPE_URL = FILE_TYPE_URL;
  userProfile: UserInfo;
  isChangeContent = false;
  addedComment = false;
  friendList: UserInfo[] = [];
  backupFriendList: UserInfo[] = [];
  index = 0;
  private functionExecuted = false;
  isLoggedIn = false;
  isOpenPopupLogin = false;
  isPreviewDataChanged = false;
  loadingPreviewData = false;
  allowedTypes = AllowedTypes;
  isShowEmojiPopup = false;

  @ViewChild('file_upload') fileUpload: ElementRef;
  @ViewChild('tagCommentPanel') tagCommentPanel: OverlayPanel;
  @ViewChild('listbox') listbox: Listbox;
  @ViewChild('textarea', { static: false, read: ElementRef }) textareaEl: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    private postsService: PostsService,
    private el: ElementRef,
    private messageService: MessageService,
    private mediaService: MediaService,
    private translateService: TranslationService,
    private store: Store,
    private router: Router,
    private toastMessageService: ToastMessageService,
    private utilityService: UtilityService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.textareaEl && changes['replyTarget'] && changes['replyTarget'].currentValue) {
      this.textareaEl.nativeElement.innerHTML = '';
      this.tagFriend(changes['replyTarget'].currentValue);
    }
  }

  ngAfterViewInit(): void {
    const textareaEl = this.textareaEl.nativeElement;
    if (!this.replyTarget) {
      textareaEl.innerHTML = this.contentComment;
    } else {
      textareaEl.innerHTML = '';
      this.tagFriend(this.replyTarget);
    }
    const taggedFriends = this.el.nativeElement.querySelectorAll('span.text-branding-primary-700');
    taggedFriends.forEach((el: HTMLElement, i: number) => {
      el.id = `tag-${this.tagCount}`;
      this.messageRanges[i].id = `tag-${this.tagCount}`;
      this.tagCount++;
    });
    if (!this.isStory && !this.isPostView) {
      this.moveCursorToEnd(textareaEl);
    }
    this.cdRef.detectChanges();
    this.translate = this.translateService.getTranslation('COMMENT');
    this.store.select(selectUserInfo).subscribe(res => {
      this.userProfile = res;
    });
    this.caretPosition = this.getCaretPosition(this.textareaEl.nativeElement);
  }

  ngOnInit() {
    const authStatus = JSON.parse(localStorage.getItem('auth_status') || '[]');
    this.isLoggedIn = authStatus.isLoggedIn;
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      if (
        !this.functionExecuted &&
        (this.textareaEl.nativeElement.textContent !== '' || this.selectedFiles.length || this.mediaComment.length)
      ) {
        const isConfirmed = window.confirm(this.translateService.getTranslation('COMMON.CONFIRM_RELOAD'));
        if (!isConfirmed) {
          this.functionExecuted = false;
          this.router.navigateByUrl(this.router.url);
        } else {
          this.functionExecuted = true;
        }
      }
    });
  }

  countNumOfLine(): number {
    const editableDiv = this.textareaEl.nativeElement;
    const lineHeight = getComputedStyle(editableDiv).lineHeight;
    const height = editableDiv.clientHeight;
    const lineCount = Math.round(height / parseFloat(lineHeight));
    return lineCount;
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.altKey && event.key === 'Enter') {
      if (this.isStory) return;
      const oldLineNumber = this.countNumOfLine();
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      if (range && selection) {
        range.deleteContents();
        let br = document.createElement('br');
        range.insertNode(br);
        if (this.countNumOfLine() <= oldLineNumber) {
          br = document.createElement('br');
          range.insertNode(br);
        }
        range.setStartAfter(br);
        range.setEndAfter(br);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    } else if (event.key === 'Enter') {
      if (this.tagCommentPanel.overlayVisible) {
        this.tagFriend(this.friendList[this.index]);
      } else {
        if (this.textareaEl.nativeElement.innerText.trim() || this.selectedFiles.length || this.mediaComment.length) {
          this.addComment();
          this.addedComment = true;
        }
      }
      event.preventDefault();
    }

    if (event.key === '@') {
      this.deleteSelectedText();
      this.hideOverlayComment();
      this.getFriendsListAndShowOverlayPanel(event);
    }

    if (event.key === ' ') {
      this.hideOverlayComment();
      this.removeTaggingSpans();
    }

    if (this.tagCommentPanel.overlayVisible) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
        const isArrowUp = event.key === 'ArrowUp';

        if (isArrowUp) {
          this.index = this.index === 0 ? this.friendList.length - 1 : this.index - 1;
        } else {
          this.index = this.index === this.friendList.length - 1 ? 0 : this.index + 1;
        }
        this.scrollIntoViewIfNeeded(isArrowUp);
      } else {
        let searchKey = this.getSubstringFromPositionToAt('@', this.caretPosition);

        if (event.key === 'Backspace' && searchKey.length === 1) {
          this.hideOverlayComment();
        } else if (event.key.length === 1 || event.key === 'Backspace') {
          searchKey = searchKey.substring(1);
          searchKey = event.key === 'Backspace' ? searchKey.slice(0, -1) : searchKey + event.key;

          this.filterFriendList(searchKey);
        }
      }
    }

    this.isChangeContent = true;
  }

  deleteSelectedText() {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      if (!range.collapsed) {
        range.deleteContents();
      }
    }
  }

  filterFriendList(searchKey: string) {
    this.index = 0;
    this.friendList = this.backupFriendList.filter(user =>
      searchKey ? user.full_name.toLowerCase().includes(searchKey.toLowerCase()) : true
    );
  }

  getNextOrPreviousSibling(element: Element, isNext: boolean = true): Element | null {
    if (element) {
      return isNext ? element.nextElementSibling : element.previousElementSibling;
    }
    return null;
  }

  scrollIntoViewIfNeeded(isArrowUp: boolean): void {
    const listboxElement = this.listbox.el.nativeElement;
    const highlightedItem = listboxElement.querySelector('.p-highlight');
    const adjacentItem = this.getNextOrPreviousSibling(highlightedItem, !isArrowUp);

    if (adjacentItem) {
      adjacentItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }

  showOverlayComment(originalEvent: Event) {
    const target = document.querySelector('span.tagging');
    if (target) {
      this.tagCommentPanel.show(originalEvent, target);
    }
  }

  hideOverlayComment() {
    this.friendList = [];
    this.tagCommentPanel.hide();
  }

  removeTaggingSpans() {
    const contentDiv = this.textareaEl.nativeElement;
    const spans = contentDiv.querySelectorAll('span.tagging');
    spans.forEach((element: Element) => {
      const textNode = document.createTextNode(element.innerHTML);
      if (element.parentNode) {
        contentDiv.replaceChild(textNode, element.parentNode);
        const range = document.createRange();
        range.setStart(textNode, textNode.length);
        range.setEnd(textNode, textNode.length);

        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    });
  }

  onKeyUp(event: KeyboardEvent): void {
    this.caretPosition = this.getCaretPosition(this.textareaEl.nativeElement);

    // Check if edit between tag
    const taggedFriend = this.el.nativeElement.querySelectorAll('span.text-branding-primary-700');
    this.messageRanges = this.messageRanges.filter(mess =>
      Array.from(taggedFriend as Iterable<HTMLElement>).some((tag: HTMLElement) => {
        const tagObj = this.messageRanges.find(ele => ele.id === tag.id)?.full_name;
        if (tagObj && tag.textContent !== tagObj) {
          this.messageRanges = this.messageRanges.filter(ele => ele.id !== tag.id);
          tag.className = '';
          tag.removeAttribute('id');
          return false;
        }
        return tag.id === mess.id;
      })
    );
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    if (clipboardData) {
      const items = clipboardData.items;
      const files = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file' || item.type.startsWith('image/') || item.type.startsWith('video/')) {
          const file = item.getAsFile()!;
          files.push(file);
        }
        if (item.type === 'text/plain') {
          const plainText = clipboardData.getData('text/plain');
          !this.previewData && this.handlePreviewLink(plainText);
          this.insertTextAtPosition(this.caretPosition, plainText, this.textareaEl.nativeElement);
          break;
        }
      }
      if (files.length) {
        this.validateFileUpload(files);
      }
      event.preventDefault();
    }
  }

  handlePreviewLink(url: string): void {
    if (this.utilityService.isWebsite(url, true)) {
      this.loadingPreviewData = true;
      this.postsService.getPreviewLink(url).subscribe(res => {
        this.previewData = res && res.success && res.data ? res.data : null;
        this.isPreviewDataChanged = true;
        this.loadingPreviewData = false;
      });
    }
  }

  onClick(): void {
    if (!this.isOpenPopupLogin) {
      if (!this.isFocusInput) {
        this.isFocusInput = true;
        setTimeout(() => {
          this.textareaEl.nativeElement.focus();
        });
      }
      if (this.isStory || this.isPostView) {
        const textareaEl = this.textareaEl.nativeElement;
        this.moveCursorToEnd(textareaEl);
        this.focusEvent.emit(true);
      }
      this.caretPosition = this.getCaretPosition(this.textareaEl.nativeElement);
    }
  }

  addComment(): void {
    const taggedFriendIds: string[] = [];
    this.el.nativeElement
      .querySelectorAll('span.text-branding-primary-700')
      .forEach((el: HTMLElement) => taggedFriendIds.push(el.id));
    if (taggedFriendIds.length > 0) {
      this.messageRanges.sort((a, b) => {
        return taggedFriendIds.indexOf(a.id as string) - taggedFriendIds.indexOf(b.id as string);
      });
    }
    this.getMessageRangesOffset();
    let comment = this.textareaEl.nativeElement;
    // Process file
    const formData = new FormData();
    for (const { file } of this.selectedFiles) {
      formData.append('files', file);
    }
    if (this.selectedFiles.length > 0) {
      this.showProgress = true;
      this.mediaService.uploadFile(formData).subscribe({
        next: ({ data }) => {
          this.medias = this.handleSuccessfulUpload(data);
          this.emitComment(comment);
        },
        error: e => {
          this.showProgress = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translate.UPLOAD_FAILED,
            detail: this.translate.ERROR_UPLOAD
          });
        }
      });
    } else {
      this.emitComment(comment);
    }
    this.addedComment = true;
  }

  emitComment(comment: HTMLElement): void {
    let listMedia = this.medias;
    const messageRanges = this.messageRanges.map(({ id, full_name, ...el }) => el);

    if (this.mediaComment?.length > 0) {
      listMedia = this.mediaComment;
    }
    if (this.isStory) {
      this.textareaEl.nativeElement.blur();
      this.focusEvent.emit(false);
    }
    this.valueComment.emit({
      content: comment.innerText,
      messageRanges: messageRanges,
      medias: listMedia,
      preview_data: this.previewData,
      mediaLocal: this.selectedFiles[0]?.original ?? ''
    });
    comment.innerHTML = '';
    this.messageRanges = [];
    this.tagCount = 0;
    this.showProgress = false;
    this.mediaComment = [];
    this.selectedFiles = [];
    this.medias = [];
    this.fileUpload.nativeElement.files = new DataTransfer().files;
    this.previewData = null;
    this.isPreviewDataChanged = false;
    this.loadingPreviewData = false;
  }

  handleSuccessfulUpload(data: any): MediaComment[] {
    if (data.length > 0) {
      return data.map((item: MediaComment) => ({
        id: item.id,
        type: item.type,
        extension: item.extension,
        original_name: item.original_name,
        signature: item.signature
      }));
    }
    return [];
  }

  getMessageRangesOffset(): void {
    const taggedName: any[] = [];
    const comment = this.textareaEl.nativeElement.innerText;
    this.messageRanges.map(el => {
      const findTaggedName = taggedName.find(ele => ele.full_name === el.full_name);
      if (findTaggedName) {
        const nextIndexOfName = comment.indexOf(el.full_name, findTaggedName.index + 1);
        el.offset = nextIndexOfName;
        findTaggedName.index = nextIndexOfName;
      } else {
        el.offset = comment.indexOf(el.full_name);
        taggedName.push({
          full_name: el.full_name,
          index: comment.indexOf(el.full_name)
        });
      }
    });
  }

  getFriendsListAndShowOverlayPanel(event: KeyboardEvent): void {
    this.postsService.getFriendTags().subscribe(res => {
      res.push(this.userProfile);
      this.backupFriendList = res;
      this.index = 0;

      const textareaEl = this.textareaEl.nativeElement.innerHTML;
      const searchKey = this.getSubstringFromPositionToAt('@', this.caretPosition);

      this.textareaEl.nativeElement.innerHTML =
        textareaEl.substring(0, textareaEl.lastIndexOf('@', this.caretPosition)) +
        textareaEl.substring(this.caretPosition);
      this.insertTextAtPosition(this.caretPosition - searchKey.length, searchKey, this.textareaEl.nativeElement, {
        className: 'tagging'
      });

      this.filterFriendList(searchKey.substring(1));
      this.showOverlayComment(event);
    });
  }

  removeSubstringFromPositionToAt(char: string, position: number): number {
    const inputString = this.textareaEl.nativeElement.innerHTML;
    const atIndex = inputString.lastIndexOf(char, position);

    if (atIndex !== -1) {
      this.textareaEl.nativeElement.innerHTML = inputString.substring(0, atIndex) + inputString.substring(position);
      return atIndex;
    }
    return -1;
  }

  getSubstringFromPositionToAt(char: string, position: number): string {
    const inputString = this.textareaEl.nativeElement.innerHTML;
    const atIndex = inputString.lastIndexOf(char, position);

    if (atIndex !== -1) {
      return inputString.substring(atIndex, position);
    }
    return '';
  }

  tagFriend(event: any): void {
    if (this.tagCommentPanel && this.tagCommentPanel.overlayVisible) {
      this.hideOverlayComment();
      this.isFocusInput = true;
    }

    let index = 0;
    const userProfile = event.value || event;
    const textareaEl = this.textareaEl.nativeElement;
    const offset = textareaEl.textContent.substring(0, textareaEl.textContent.lastIndexOf('@', this.caretPosition));

    let span = document.querySelector('span.tagging');
    if (span) {
      index = this.textareaEl.nativeElement.innerHTML.indexOf(span?.outerHTML);
      span.parentNode?.removeChild(span);
    }

    this.insertTextAtPosition(index, userProfile.full_name, textareaEl, {
      className: 'text-branding-primary-700',
      id: `tag-${this.tagCount}`,
      entityName: '&nbsp;'
    });
    this.messageRanges.map(el => {
      if (el.offset > offset.length - 1) {
        el.offset = el.offset + userProfile.full_name.length;
      }
    });
    this.messageRanges = [
      ...this.messageRanges,
      {
        id: `tag-${this.tagCount}`,
        entity_id: userProfile.id,
        full_name: userProfile.full_name,
        entity_type: 'USER',
        length: userProfile.full_name.length,
        offset: 0
      }
    ];
    this.tagCount++;
    this.isFocusInput = true;
  }

  moveCursorToEnd(el: HTMLElement): void {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);
  }

  handleUploadFileClick(): void {
    if (!this.isOpenPopupLogin) {
      this.fileUpload.nativeElement.click();
    }
  }

  handleFileUpload(event: any): void {
    this.selectedFiles = [];
    this.isFocusInput = true;
    const files = event.target.files || event.dataTransfer.files;
    this.validateFileUpload(files);
  }

  validateFileUpload(files: File[]): void {
    if (this.selectedFiles.length + files.length > MAXIMUM_UPLOAD_COUNT) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.MAXIMUM_FILE,
        detail: this.translate.COMMENT_ACHIEVE_MAXIMUM
      });
      return;
    }
    // Maximum allowed size for videos in MB
    const maxSizeInBytesImage = environment.FILE_SIZE_LIMITS.image;
    const maxSizeInBytesVideo = environment.FILE_SIZE_LIMITS.video;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Validate file type
      if (!AllowedTypes.includes(file.type)) {
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'COMMON.ALLOW_IMG_VIDEO');
        continue;
      }

      // Validate file size
      let maxSizeInBytes = file.type.startsWith('image/') ? maxSizeInBytesImage : maxSizeInBytesVideo;
      if (file.size >= maxSizeInBytes) {
        this.toastMessageService.addToastMessage(TOAST_MESSAGE_SEVERITY_LEVELS.error, 'COMMON.FILE_MAXIMUM');
        continue;
      }
      this.showProgress = true;
      const url = URL.createObjectURL(file);
      this.selectedFiles = [
        {
          file: file,
          thumbnail: '0x',
          original: url,
          upload: true,
          type: file.type.startsWith('image/') ? MEDIA_TYPE.image : MEDIA_TYPE.video
        }
      ];
      this.showProgress = false;
      this.isChangeContent = true;
    }
  }

  deleteFile(index: number): void {
    if (index < this.selectedFiles.length) {
      this.selectedFiles.splice(index, 1);

      let newFileList = new DataTransfer();
      this.selectedFiles.forEach((file: any) => {
        newFileList.items.add(file.file);
      });
      this.fileUpload.nativeElement.files = newFileList.files;
    } else {
      this.mediaComment.splice(index - this.selectedFiles.length, 1);
    }
    this.isChangeContent = true;
  }

  onSelectEmoji(event: any): void {
    this.insertTextAtPosition(this.caretPosition, event.emoji.native, this.textareaEl.nativeElement, undefined, true);
    this.caretPosition = this.getCaretPosition(this.textareaEl.nativeElement);
    this.isFocusInput = true;
    this.isChangeContent = true;
  }

  getCaretPosition(element: Element): number {
    let caretPos = 0;
    let sel, range;

    if (window.getSelection) {
      sel = window.getSelection();
      if (sel && sel.rangeCount) {
        range = sel.getRangeAt(0);
        let preCaretRange = document.createRange();
        preCaretRange.setStart(element, 0);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        let content = preCaretRange.cloneContents();
        let tempDiv = document.createElement('div');
        tempDiv.appendChild(content);
        if (tempDiv.innerHTML.charAt(tempDiv.innerHTML.length - 1) === '>') {
          caretPos = tempDiv.innerHTML.substring(0, tempDiv.innerHTML.lastIndexOf('<')).length;
        } else {
          caretPos = tempDiv.innerHTML.length;
        }
      }
    }
    return caretPos;
  }

  insertTextAtPosition(
    position: number,
    text: string,
    element: HTMLElement,
    property?: { className?: string; id?: string; entityName?: string },
    skipSelectDeletion = false
  ): void {
    const selection = window.getSelection();
    if (selection && !skipSelectDeletion) {
      selection.deleteFromDocument();
    }
    const startText = element.innerHTML.substring(0, position);
    const endText = element.innerHTML.substring(position);
    const entityName = property?.entityName || '';

    const span = document.createElement('span');
    if (property) {
      span.className = property.className || '';
      span.id = property.id || '';
    }
    span.innerText = text;
    element.innerHTML = startText + span.outerHTML + entityName;
    this.moveCursorToEnd(element);
    element.insertAdjacentHTML('beforeend', endText);
    this.caretPosition = this.getCaretPosition(element);
  }

  createMediaUrl(urlString: string, style = FILE_TYPE_URL.thumbnail): string {
    if (style === FILE_TYPE_URL.thumbnail) {
      return urlString.includes('blob:') ? urlString : checkUrl(urlString, style) + FILE_EXTENSION.image;
    } else {
      return urlString.includes('blob:') ? urlString : checkUrl(urlString, style) + FILE_EXTENSION.video;
    }
  }

  cancelEditComment() {
    this.isEditCommentChange.emit(false);
  }

  onShowOverlayPanel() {
    setTimeout(() => {
      this.isShowEmojiPopup = true;
    });
    (document.querySelector('.emoji-mart-anchor') as HTMLElement).click();
  }

  onHideOverlayPanel() {
    this.isShowEmojiPopup = false;
  }

  resetTextarea() {
    if (this.textareaEl) {
      this.textareaEl.nativeElement.textContent = '';
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.textareaEl.nativeElement.textContent != '' || this.selectedFiles.length || this.mediaComment.length) {
      $event.returnValue = true;
    }
  }

  openPopup(event: any) {
    this.isOpenPopupLogin = event;
  }
}
