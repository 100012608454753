import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@app/lib/api/api-client';
import { UserAPI } from '@app/lib/api/user/api.user.model';
import { environment } from '@env/environment';
import { Observable, catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = environment.baseURL;
  authData = {
    phone_or_email: '',
    area_code: '',
    password: '',
    token: ''
  };
  isNotVerified = false;
  emailNotVerified = '';
  private api: ApiClient;

  constructor(private http: HttpClient) {
    this.api = new ApiClient(http, { responseTimeout: environment.API_TIMEOUT });
  }

  deactivateUser(body: UserAPI.ReasonToLeave): Observable<any> {
    return this.api.user.deactivateUser(body);
  }

  getUserInfo(userId: string): Observable<any> {
    return this.api.user.getUserInfo(userId);
  }

  // Error handling
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  signUp(data: UserAPI.SignUp): Observable<any> {
    return this.api.user.signUp(data).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  thirdPartySignUp(data: UserAPI.SocialUser): Observable<any> {
    return this.api.user.thirdPartySignUp(data);
  }

  verifyCode(data: UserAPI.VerifyCode): Observable<any> {
    return this.api.user.verifyCode(data);
  }

  sendCode(data: UserAPI.SendCode): Observable<any> {
    return this.api.user.sendCode(data);
  }

  sendOTPCode(data: UserAPI.SendCode): Observable<any> {
    return this.api.user.sendOTPCode(data);
  }

  verifyOTPCode(data: UserAPI.VerifyCode): Observable<any> {
    return this.api.user.verifyOTPCode(data);
  }

  setNewPassword(): Observable<any> {
    return this.api.user.setNewPassword(this.authData);
  }

  confirmPassword(password: string): Observable<boolean> {
    return this.api.user.confirmPassword(password);
  }

  blockUser(userId: string): Observable<any> {
    return this.api.user.blockUser(userId);
  }

  unblockUser(userId: string): Observable<any> {
    return this.api.user.unblockUser(userId);
  }

  isBlockingUser(userId: string): Observable<any> {
    return this.api.user.isBlockingUser(userId);
  }

  isBlockedUser(userId: string): Observable<any> {
    return this.api.user.isBlockedUser(userId);
  }

  getBlockingList(pageNum: number, pageSize: number): Observable<any> {
    return this.api.user.getBlockingList(pageNum, pageSize);
  }

  deleteUser(body: UserAPI.ReasonToLeave) {
    return this.api.user.deleteUser(body);
  }

  undeleteUser() {
    return this.api.user.undeleteUser();
  }

  getTwoFaCode() {
    return this.api.user.getTwoFaCode();
  }

  setUpTwoFaCode() {
    return this.api.user.setUpTwoFaCode();
  }

  removeTwoFa(otp: string) {
    return this.api.user.removeTwoFa(otp);
  }

  verifyTwoFAOtp(body: UserAPI.VerifyOTPTwoFa) {
    return this.api.user.verifyTwoFaOtp(body);
  }

  changePassword(payload: UserAPI.ChangePassword): Observable<any> {
    return this.api.user.changePassword(payload);
  }

  getRecoveryCode() {
    return this.api.user.getRecoveryCode();
  }

  generateRecoveryCode() {
    return this.api.user.generateRecoveryCode();
  }

  getRecoveryCodeFile(language: string) {
    return this.api.user.getRecoveryCodeFile(language);
  }

  putUserPrivacySettings(value: string, setting_type: string) {
    return this.api.user.putUserPrivacySettings(value, setting_type);
  }

  hideMessage() {
    return this.api.user.hideMessage();
  }

  removeUserAvatar() {
    return this.api.user.removeUserAvatar();
  }

  getUserV2(params: UserAPI.GetUserV2Params | any): Observable<any> {
    return this.api.user.getUserV2(params);
  }

  getBonusModalInfo(language: string) {
    return this.api.user.getBonusModalInfo(language);
  }

  requiresGuidance() {
    return this.api.user.requiresGuidance();
  }
}
